import shallow from "zustand/shallow";
import useStore from "../../store/store";
import useApplicationChannel from "./useApplicationChannel";

const useSSOParams = () => {
  const [envQueryParam, appId, storedSelectedProducts] = useStore(
    state => [
      state.content
        .find(bundle => bundle.configs)
        ?.configs?.find(({ label }) => label === "ssoEnvironmentToken")?.value,
      state.applicationReferenceId,
      state.applicationProductsSelected?.map(product => product.productType) ?? [],
    ],
    shallow
  );

  const encodedAppRefId = encodeURIComponent(appId);
  const channelIdentifier = useApplicationChannel();
  const selectedProducts = encodeURIComponent(String(storedSelectedProducts));
  const preferredLanding = window.UI_ENV === "dev" ? "SBB_DAO_DEV" : envQueryParam;

  const ssoUrl = `?PrfrdLanding=${preferredLanding}&applicationChannel=${channelIdentifier}&selectedProducts=${selectedProducts}&applicationReferenceId=${encodedAppRefId}`;

  if (!appId || !channelIdentifier) {
    return null;
  }

  // default case
  return ssoUrl;
};

export default useSSOParams;
