import { useNavigate } from "react-router-dom";
import useStore from "../../store/store";
import useSSOParams from "./useSSOParams";
import getContentReader from "../getContentReader";
import usePreSubmitTreatment from "./usePreSubmitTreatment";

const usePrefillHandler = () => {
  const handleApplicantPrefill = useStore(state => state.handleApplicantPrefill);
  const navigate = useNavigate();
  const ssoSignInContent = usePreSubmitTreatment("branchPreSubmitFriction", "ssoSignIn");
  const ssoParams = useSSOParams();

  if (!ssoSignInContent) {
    // should never happen but let callsite handle this and display a loading spinner
    return { ssoUrl: null, handlePrefill: null };
  }

  const ssoUrl = `${getContentReader(ssoSignInContent)("buttonLinkTarget")}${ssoParams}`;
  const handlePrefill = async () => {
    try {
      const nextPage = await handleApplicantPrefill();
      navigate(nextPage);
    } catch (error) {
      // only proceed to SSO page if response was 401 Unauthorized
      if (error.code === 401) {
        // NSBOAO-24966 - Go to redirect location if returned in error due to cookie scoping.
        // Otherwise go to the sso page
        const redirectUrl = error.redirectLocation || ssoUrl;
        window.open(redirectUrl, "_self");
      } else {
        navigate("/existing-data-failure");
      }
    }
  };

  return { ssoUrl, handlePrefill };
};

export default usePrefillHandler;
