import { useCallback } from "react";
import { Controller, useForm, FormProvider } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import shallow from "zustand/shallow";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import NumberFormat from "react-number-format";
import { CenteredRow, Col, Grid, Row } from "../../shared/Layout";
import NavigationButtons from "../../shared/NavigationButtons";
import { blockedTINs, invalidEINPatterns } from "../../../constants/taxIdNumbers";
import useStore from "../../../store/store";
import Select from "../../shared/Select";
import { EIN, SSN } from "../../../constants/businessTaxIdTypes";
import SectionHeader from "../../shared/SectionHeader";
import useScrollToComponent from "../../../utils/hooks/useScrollToComponent";
import SecretTextInput from "../../shared/SecretTextInput";
import selectFirstFieldWithError from "../../../utils/selectFirstFieldWithError";
import useSubmitWithErrorHandling from "../../../utils/hooks/useSubmitWithErrorHandling";
import DisabledField from "../../shared/DisabledField";
import useRouting from "../../../utils/hooks/useRouting";
import UnsupportedBusinessTypesSection, {
  unsupportedBusinessTypesSchema,
  useUnsupportedBusinessTypesDefault,
} from "../../shared/UnsupportedBusinessTypesSection";
import { useInputId, useDropdownId } from "../../../utils/hooks/usePageScopedId";

const einSchema = yup
  .string()
  .required("Enter your Employer Identification Number")
  .min(9, "Enter a valid 9-digit Employer Identification Number")
  .max(9, "Enter a valid 9-digit Employer Identification Number")
  .test(
    "validateEmployerIdentificationNumber",
    "Enter a valid 9-digit Employer Identification Number",
    value => !(blockedTINs.includes(value) || invalidEINPatterns.some(pat => pat.test(value)))
  );

const taxIdSchema = yup.object().shape({
  ...unsupportedBusinessTypesSchema,
  taxIdType: yup.string().oneOf([SSN, EIN]),
  ein: yup.string().when("taxIdType", {
    is: EIN,
    then: schema => schema.concat(einSchema),
    otherwise: schema => schema.notRequired(),
  }),
});

const SolePropTaxInfo = ({ areStateDocsFiled, hasMultipleBusinessOwners }) => {
  const [submitPage, applicantTaxId, businessTaxIdType, businessTaxId] = useStore(
    state => [
      state.pageSubmit.submitSoleProp,
      state.applicantTaxId,
      state.businessTaxIdType,
      state.businessTaxId,
    ],
    shallow
  );
  const unsupportedBusinessTypesDefault = useUnsupportedBusinessTypesDefault();
  const formMethods = useForm({
    resolver: yupResolver(taxIdSchema),
    shouldFocusError: false,
    defaultValues: {
      ...unsupportedBusinessTypesDefault,
      taxIdType: businessTaxIdType || SSN,
      ein: businessTaxIdType === EIN ? businessTaxId : "",
    },
  });
  const {
    register,
    control,
    watch,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = formMethods;
  const navigate = useNavigate();
  const [backRoute, nextRoute] = useRouting();
  const submitHandler = useCallback(
    async ({ isUnsupportedBusinessType, isTrust, taxIdType, ein }) => {
      // We can pass applicantTaxId for taxIdType=SSN since the ssn field is disabled for that case
      const { isEligibleCustomer } = await submitPage({
        isUnsupportedBusinessType,
        isTrust,
        taxId: taxIdType === SSN ? applicantTaxId : ein,
        taxIdType,
        areStateDocsFiled,
        hasMultipleBusinessOwners,
      });
      navigate(isEligibleCustomer ? nextRoute : "/cannot-proceed");
    },
    [nextRoute, applicantTaxId, submitPage, navigate, areStateDocsFiled, hasMultipleBusinessOwners]
  );
  const submitWithErrorHandling = useSubmitWithErrorHandling(submitHandler);
  const headerRef = useScrollToComponent();

  const isEINSelected = watch("taxIdType") === EIN;
  const einFieldId = useInputId("solePropEIN");

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(submitWithErrorHandling, selectFirstFieldWithError)}>
        <Grid>
          <CenteredRow>
            <Col lg={8} md={8} sm={4}>
              {/* TODO: Change to sentence case when merging into HRBR feature branch */}
              <SectionHeader compact ref={headerRef} title="Business Type" />
            </Col>
          </CenteredRow>
          <Row>
            <Col lg={8} md={8} offset={{ lg: 2 }} sm={4}>
              <UnsupportedBusinessTypesSection />
            </Col>
          </Row>
          <CenteredRow>
            <Col lg={8} md={8} sm={4}>
              <SectionHeader compact title="Tax Information" />
            </Col>
          </CenteredRow>
          <Row>
            <Col lg={4} md={4} offset={{ lg: 2 }} sm={4}>
              <Select id={useDropdownId("taxIdType")} label="Business Tax ID Type" {...register("taxIdType")}>
                <option disabled>Select a business tax ID type</option>
                <option value={SSN}>Social Security Number (SSN)</option>
                <option value={EIN}>Employer Identification Number (EIN)</option>
              </Select>
            </Col>
            <Col lg={3} md={3} sm={4}>
              {!isEINSelected && (
                <DisabledField
                  helper="This is the 9-digit identification number you entered on your previous year's tax return or letter from the Internal Revenue Service"
                  label="Business Tax ID (SSN)"
                  value={`•••-••-${applicantTaxId.slice(5)}`}
                />
              )}
              {isEINSelected && (
                <Controller
                  render={({ field: { onChange, ref, ...rest } }) => (
                    <NumberFormat
                      customInput={SecretTextInput}
                      getInputRef={ref}
                      id={einFieldId}
                      label="Business Tax ID (EIN)"
                      helper="This is the 9-digit identification number you entered on your previous year's tax return or letter from the Internal Revenue Service"
                      autoComplete="off"
                      placeholder="__-_______"
                      format="##-#######"
                      mask="_"
                      secretMask="••-•••"
                      hideIf={v => v.replaceAll("_", "").replaceAll("-", "").length >= 9}
                      error={errors?.ein?.message}
                      onValueChange={v => onChange(v.value)}
                      {...rest}
                    />
                  )}
                  name="ein"
                  control={control}
                  shouldUnregister
                />
              )}
            </Col>
          </Row>
        </Grid>
        <NavigationButtons backRoute={backRoute} nextLoading={isSubmitting} />
      </form>
    </FormProvider>
  );
};

export default SolePropTaxInfo;
