import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import shallow from "zustand/shallow";
import { SBB_BRANCH, SBB_CAFE, SBB_WEB } from "../../constants/applicationChannelTypes";
import useStore from "../../store/store";
import { isFeatureEnabled } from "../configSelector";
import { ALLOW_FORCE_BROWSING } from "../handleForceBrowsing";
import useApplicationChannel from "./useApplicationChannel";
import { setCustomAttributeForNewRelicData } from "../newRelic";

const useInitialRouting = loadingRoute => {
  const [initialRoutingComplete, setInitialRoutingComplete] = useState(false);

  const channel = useApplicationChannel();

  useEffect(() => {
    if (channel) {
      setCustomAttributeForNewRelicData("applicationChannel", channel);
    }
  }, [channel]);

  const [
    allContentLoaded,
    applicationCreated,
    contentLoadingError,
    desiredLanding,
    isWebEnabled,
    isCafeEnabled,
    isBranchCriticalTechError,
    landingPage,
    hasProcessedAdobeTarget,
  ] = useStore(
    state => [
      state.allContentLoaded,
      state.applicationCreated,
      state.contentLoadingError,
      state.queryParams.desiredLanding,
      isFeatureEnabled(state, "webChannelEnabled"),
      isFeatureEnabled(state, "cafeChannelEnabled"),
      isFeatureEnabled(state, "branchCriticalTechErrorEnabled"),
      state.getTargetTestValue("landingPage"),
      state.hasProcessedAdobeTarget,
    ],
    shallow
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (initialRoutingComplete || contentLoadingError || ALLOW_FORCE_BROWSING) {
      return;
    }

    // This ensures that any query params will actually stay present during this initial loading.
    // This is mostly useful for query params that we do not strip out in useQueryParams.
    const loadingRouteWithQueryParams = loadingRoute + window.location.search;

    // AB TEST: Show loading if Adobe Target has not been processed, since the landing page depends on AB decision from it
    if (!allContentLoaded || !hasProcessedAdobeTarget) {
      navigate(loadingRouteWithQueryParams, { replace: true });
      return;
    }

    // maintain query params even when we reroute to the main page as well for the same reason.
    const mainRoute =
      landingPage !== "productSelection"
        ? `/${window.location.search}`
        : `/product-selection${window.location.search}`;

    // if applicationCreated is true here, this implies that an applicationReferenceId has already been created,
    // which is true if it was obtained from the C1_TGT cookie -- in this case, go to the existing customer check
    // transitional page
    if (applicationCreated) {
      navigate("/existing-customer-check", { replace: true });
      setInitialRoutingComplete(true);
      return;
    }

    // desiredLanding query parameter will be captured and validated by useQueryParams, and if it is set in
    // the store we are safe to navigate directly to it
    if (desiredLanding) {
      navigate(`/${desiredLanding}`, { replace: true });
      setInitialRoutingComplete(true);
      return;
    }

    switch (channel) {
      case SBB_BRANCH:
        navigate(isBranchCriticalTechError ? "/application-unavailable" : mainRoute, {
          replace: true,
        });
        setInitialRoutingComplete(true);
        break;
      case SBB_CAFE:
        navigate(isCafeEnabled ? mainRoute : "/application-unavailable", { replace: true });
        setInitialRoutingComplete(true);
        break;
      case SBB_WEB:
        navigate(isWebEnabled ? mainRoute : "/application-unavailable", { replace: true });
        setInitialRoutingComplete(true);
        break;
      default:
        navigate(loadingRouteWithQueryParams, { replace: true });
    }
  }, [
    initialRoutingComplete,
    allContentLoaded,
    contentLoadingError,
    navigate,
    loadingRoute,
    channel,
    isWebEnabled,
    isCafeEnabled,
    isBranchCriticalTechError,
    applicationCreated,
    desiredLanding,
    landingPage,
    hasProcessedAdobeTarget,
  ]);
};

export default useInitialRouting;
