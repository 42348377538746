import styled from "styled-components";
import { Col, Grid, CenteredRow } from "../Layout";
import Page from "../Page";

const LoadingSpacer = styled.div`
  min-height: 400px;
`;

const LoadingSpinnerText = styled.div`
  font-size: var(--grv-size-font-medium-3);
  font-weight: var(--grv-font-weight-light);
  margin-top: var(--grv-size-spacing-large-2);
`;

const ScreenReaderOnlyText = styled.span`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
`;

const LoadingSpinnerPage = ({ loadingSpinnerText = "" }) => (
  <Page>
    <div
      role="alert"
      aria-live="assertive"
      tabIndex="-1"
      className="grv-spinner grv-spinner--active grv-spinner--dark"
    >
      {loadingSpinnerText ? (
        <LoadingSpinnerText>{loadingSpinnerText}</LoadingSpinnerText>
      ) : (
        <ScreenReaderOnlyText>Loading, please wait...</ScreenReaderOnlyText>
      )}
    </div>
    <Grid aria-busy>
      <CenteredRow>
        <Col lg={12} md={8} sm={4}>
          <LoadingSpacer />
        </Col>
      </CenteredRow>
    </Grid>
  </Page>
);

export default LoadingSpinnerPage;
