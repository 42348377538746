import Bowser from "bowser";
import cuid from "cuid";
import { sendErrorToNewRelic, setCustomAttributeForNewRelicData } from "../utils/newRelic";
import getPotomacIds from "../utils/getPotomacIds";
import { SBB_BRANCH, SBB_CAFE, SBB_WEB } from "../constants/applicationChannelTypes";

export const correlationId = cuid();
setCustomAttributeForNewRelicData("correlationId", correlationId);
const errorsDisabled =
  (window?.UI_ENV === "local" && process?.env?.REACT_APP_DISABLE_ERRORS === "true") || false;

const configOverrides = (() => {
  try {
    return JSON.parse(process.env.REACT_APP_CONFIG_OVERRIDES);
  } catch (_) {
    return null;
  }
})();

const getAdobeVisitorIdFromWindow = () => {
  try {
    return window.visitor?.getMarketingCloudVisitorID();
  } catch (error) {
    console.error(`Error occurred while attempting to getMarketingCloudVisitorID`, error);
    sendErrorToNewRelic(error);
    return null;
  }
};

// future performance improvement: handle this XSRF token once and put result in store?
let xsrfToken = null;
export const getXSRFToken = () => {
  if (xsrfToken === null) {
    const startIndex = document.cookie.indexOf("XSRF-TOKEN=");
    if (startIndex !== -1) {
      const rest = document.cookie.slice(startIndex + "XSRF-TOKEN=".length);
      const endIndex = rest.indexOf(";");
      xsrfToken = endIndex === -1 ? rest : rest.slice(0, endIndex);
    }
  }
  return xsrfToken;
};

const getHeaders = ({ v2 = false, includeToken = false } = {}) => ({
  "Client-Correlation-Id": correlationId,
  "Content-Type": "application/json",
  Accept: v2 ? "application/json;v=2" : "application/json;v=1",
  ...(includeToken && { "X-XSRF-TOKEN": getXSRFToken() }),
});

const baseHeaders = getHeaders();

// Retry wrapper method

const callWithRetry = async (apiFunction, maxAttempts, delay, propagateErrors, ...args) => {
  const executeCall = async attempt => {
    try {
      const result = await apiFunction(...args);
      return result;
    } catch (err) {
      console.log(err); // log the error before moving to the next iteration
      sendErrorToNewRelic(err); // send error to new relic as well
      if (propagateErrors) {
        if (attempt < maxAttempts) {
          await new Promise(resolve => setTimeout(resolve, delay));
          return executeCall(attempt + 1);
        }
        // if propagating errors, we should throw after the final attempt
        throw new Error(`Failed to execute function call after ${maxAttempts} attempts: ${err.message}`);
      } else {
        if (attempt < maxAttempts) {
          // if not propagating errors, we do not need to wait for the delay period
          return new Promise(resolve => setTimeout(() => resolve(executeCall(attempt + 1)), delay));
        }
        return undefined;
      }
    }
  };

  return executeCall(1);
};

// Configuration and UI Content

export const getConfig = async (
  // set desired retry parameters for API call
  maxAttempts = 4,
  delay = 2000,
  propagateErrors = true
) => {
  const getConfigCall = async () => {
    const res = await fetch("/protected/26818/small-business/sbao-app/ui-config", {
      method: "GET",
      headers: baseHeaders,
    });

    if (!res.ok && !errorsDisabled) {
      throw new Error("Something went wrong while loading UI content");
    }

    if (configOverrides) {
      const { configs } = await res.json();
      return {
        configs: configs.map(({ label, value }) => ({ label, value: configOverrides[label] ?? value })),
      };
    }
    return res.json();
  };

  return callWithRetry(getConfigCall, maxAttempts, delay, propagateErrors);
};

export const getUIContentV2 = async (
  // set desired retry parameters for API call
  maxAttempts = 4,
  delay = 2000,
  propagateErrors = true
) => {
  const getContentCall = async () => {
    const res = await fetch("/protected/26818/small-business/dao-ui-services/ui-content", {
      method: "GET",
      headers: getHeaders({ v2: true }),
    });

    if (!res.ok && !errorsDisabled) {
      throw new Error("Something went wrong while loading UI content");
    }

    return res.json();
  };

  return callWithRetry(getContentCall, maxAttempts, delay, propagateErrors);
};

export const getComplianceDisclosures = async (
  // set desired retry parameters for API call
  maxAttempts = 4,
  delay = 2000,
  propagateErrors = true
) => {
  const getComplianceDisclosuresCall = async () => {
    const res = await fetch("/protected/26818/small-business/sbao-app/ui-content/disclosures/compliance", {
      method: "GET",
      headers: baseHeaders,
    });

    if (!res.ok && !errorsDisabled) {
      throw new Error("Something went wrong while loading UI content");
    }

    return res.json();
  };

  return callWithRetry(getComplianceDisclosuresCall, maxAttempts, delay, propagateErrors);
};

export const getUIDisclosures = async (
  // set desired retry parameters for API call
  maxAttempts = 4,
  delay = 2000,
  propagateErrors = true
) => {
  const getUIDisclosuresCall = async () => {
    const res = await fetch("/protected/26818/small-business/sbao-app/ui-content/disclosures/ui", {
      method: "GET",
      headers: baseHeaders,
    });

    if (!res.ok && !errorsDisabled) {
      throw new Error("Something went wrong while loading UI content");
    }

    return res.json();
  };

  return callWithRetry(getUIDisclosuresCall, maxAttempts, delay, propagateErrors);
};

export const getBusinessIndustries = async (
  // set desired retry parameters for API call
  maxAttempts = 4,
  delay = 2000,
  propagateErrors = true
) => {
  const getBusinessIndustriesCall = async () => {
    const res = await fetch("/protected/26818/small-business/sbao-app/ui-content/business-industries", {
      method: "GET",
      headers: baseHeaders,
    });

    if (!res.ok && !errorsDisabled) {
      throw new Error("Something went wrong while loading UI content");
    }

    return res.json();
  };

  return callWithRetry(getBusinessIndustriesCall, maxAttempts, delay, propagateErrors);
};

export const getCustomerMessages = async (
  // set desired retry parameters for API call
  maxAttempts = 4,
  delay = 2000,
  propagateErrors = true
) => {
  const getCustomerMessagesCall = async () => {
    const res = await fetch("/protected/26818/small-business/sbao-app/ui-content/customer-messages", {
      method: "GET",
      headers: baseHeaders,
    });

    if (!res.ok && !errorsDisabled) {
      throw new Error("Something went wrong while loading UI content");
    }

    return res.json();
  };

  return callWithRetry(getCustomerMessagesCall, maxAttempts, delay, propagateErrors);
};

export const getRiskyAndProhibitedActivities = async (
  // set desired retry parameters for API call
  maxAttempts = 4,
  delay = 2000,
  propagateErrors = true
) => {
  const getRiskyAndProhibitedActivitiesCall = async () => {
    const res = await fetch(
      "/protected/26818/small-business/sbao-app/ui-content/risky-and-prohibited-activities",
      {
        method: "GET",
        headers: baseHeaders,
      }
    );

    if (!res.ok && !errorsDisabled) {
      throw new Error("Something went wrong while loading UI content");
    }

    return res.json();
  };

  return callWithRetry(getRiskyAndProhibitedActivitiesCall, maxAttempts, delay, propagateErrors);
};

export const getProducts = async (
  // set desired retry parameters for API call
  maxAttempts = 4,
  delay = 2000,
  propagateErrors = true
) => {
  const getProductsCall = async () => {
    const res = await fetch("/protected/26818/small-business/sbao-app/products", {
      method: "GET",
      headers: baseHeaders,
    });

    if (!res.ok && !errorsDisabled) {
      throw new Error("Something went wrong while loading UI content");
    }

    return res.json();
  };

  return callWithRetry(getProductsCall, maxAttempts, delay, propagateErrors);
};

// Application
export const createApplication = async (
  { contentVersions } = {},
  { applicationChannel, trackingId } = {},
  // set desired retry parameters for API call
  maxAttempts = 4,
  delay = 2000,
  propagateErrors = true
) => {
  // no need to include this initial setup logic in the retryable createApplicationCall since we do not want
  // to recalculate it each time and do not want to retry if the tracking ID error is thrown

  // for browser list, see: https://github.com/lancedikson/bowser/blob/master/src/constants.js
  const browser = Bowser.parse(window.navigator.userAgent)?.browser?.name ?? "unknown";
  const { clickstreamDomainUserId, clickstreamDomainSessionId } = getPotomacIds(document.cookie);
  // SBB_CAFE and SBB_BRANCH both require tracking ID to be present, but for SBB_WEB it is not allowed
  // XOR operator as explained in https://www.howtocreate.co.uk/xor.html
  if (!trackingId !== ![SBB_BRANCH, SBB_CAFE].includes(applicationChannel)) {
    throw new Error("Insufficient/inaccurate query params supplied");
  }

  const adobeVisitorId = getAdobeVisitorIdFromWindow() || "";

  const body = JSON.stringify({
    browser,
    applicationChannel: applicationChannel ?? SBB_WEB,
    clickstreamDomainUserId,
    clickstreamDomainSessionId,
    contentVersions,
    // if there was a trackingId query param, provide it in request body
    ...(trackingId && { trackingId }),
    ...(adobeVisitorId && { adobeVisitorId }),
  });

  const createApplicationCall = async () => {
    const res = await fetch("/protected/26818/small-business/sbao-app/applications", {
      method: "POST",
      headers: getHeaders({ includeToken: true }),
      body,
    });

    if (!res.ok && !errorsDisabled) {
      throw new Error("Failed to create application");
    }

    return res.json();
  };

  return callWithRetry(createApplicationCall, maxAttempts, delay, propagateErrors);
};

const updateUnauthenticatedApplication = async (
  applicationReferenceId,
  applicationData,
  // desired retry parameters for API call
  // set and passed in by updateApplication function
  maxAttempts,
  delay,
  propagateErrors
) => {
  const updateUnauthenticatedApplicationCall = async () => {
    const encodedAppRefId = encodeURIComponent(applicationReferenceId);
    const body = JSON.stringify(applicationData);
    const res = await fetch(`/protected/26818/small-business/sbao-app/applications/${encodedAppRefId}`, {
      method: "PATCH",
      headers: getHeaders({ includeToken: true }),
      body,
    });

    if (!res.ok && !errorsDisabled) {
      throw new Error("Failed to update application");
    }

    return res.json();
  };

  return callWithRetry(updateUnauthenticatedApplicationCall, maxAttempts, delay, propagateErrors);
};

const updateAuthenticatedApplication = async (
  applicationReferenceId,
  applicationData,
  // desired retry parameters for API call
  // set and passed in by updateApplication function
  maxAttempts,
  delay,
  propagateErrors
) => {
  const updateAuthenticatedApplicationCall = async () => {
    const encodedAppRefId = encodeURIComponent(applicationReferenceId);
    const body = JSON.stringify(applicationData);
    const res = await fetch(
      `/protected/26818/small-business/dao-ui-services/authenticated/applications/${encodedAppRefId}`,
      {
        method: "PATCH",
        headers: getHeaders({ v2: true, includeToken: true }),
        body,
      }
    );

    if (!res.ok && !errorsDisabled) {
      throw new Error("Failed to update application");
    }

    return res.json();
  };

  return callWithRetry(updateAuthenticatedApplicationCall, maxAttempts, delay, propagateErrors);
};

export const updateApplication = async (
  isApplicantAuthenticated,
  applicationReferenceId,
  applicationData,
  // set desired retry parameters for API call
  maxAttempts = 4,
  delay = 2000,
  propagateErrors = true
) => {
  if (isApplicantAuthenticated) {
    return updateAuthenticatedApplication(
      applicationReferenceId,
      applicationData,
      maxAttempts,
      delay,
      propagateErrors
    );
  }

  return updateUnauthenticatedApplication(
    applicationReferenceId,
    applicationData,
    maxAttempts,
    delay,
    propagateErrors
  );
};

// Retry logic ommitted for this function, since it is not meant to be called directly
const submitUnauthenticatedApplication = async applicationReferenceId => {
  const encodedAppRefId = encodeURIComponent(applicationReferenceId);
  const res = await fetch(
    `/protected/26818/small-business/sbao-app/applications/${encodedAppRefId}/submit-application`,
    {
      method: "POST",
      headers: getHeaders({ includeToken: true }),
    }
  );

  if (!res.ok && !errorsDisabled) {
    throw new Error("Failed to submit application");
  }

  return res.json();
};

// Retry logic ommitted for this function, since it is not meant to be called directly
const submitAuthenticatedApplication = async applicationReferenceId => {
  const encodedAppRefId = encodeURIComponent(applicationReferenceId);
  const res = await fetch(
    `/protected/26818/small-business/dao-ui-services/authenticated/applications/${encodedAppRefId}/submit-application`,
    {
      method: "POST",
      headers: getHeaders({ v2: true, includeToken: true }),
    }
  );

  if (!res.ok && !errorsDisabled) {
    throw new Error("Failed to submit application");
  }

  return res.json();
};

// Retry logic ommitted for this function, since retries for submitted applications are handled by backend teams
export const submitApplication = async (isApplicantAuthenticated, applicationReferenceId) => {
  if (isApplicantAuthenticated) {
    return submitAuthenticatedApplication(applicationReferenceId);
  }

  return submitUnauthenticatedApplication(applicationReferenceId);
};

// Retry logic ommitted for this function, since it is not meant to be called directly
export const addEventToApplication = async (applicationReferenceId, event, timestamp) => {
  const encodedAppRefId = encodeURIComponent(applicationReferenceId);
  const eventsEndpoint = `/protected/26818/small-business/sbao-app/applications/${encodedAppRefId}/events`;
  const eventsRequest = {
    method: "POST",
    headers: getHeaders({ includeToken: true }),
    body: JSON.stringify({
      event,
      timestamp: timestamp || new Date().toISOString(),
    }),
  };

  const res = await fetch(eventsEndpoint, eventsRequest);

  if (!res.ok && !errorsDisabled) {
    throw new Error("Failed to add event to application");
  }
};

export const addCriticalEventToApplication = async ({
  applicationReferenceId,
  event,
  timestamp,
  // set desired retry parameters for API call
  maxAttempts = 4,
  delay = 2000,
  propagateErrors = true,
}) =>
  callWithRetry(
    addEventToApplication,
    maxAttempts,
    delay,
    propagateErrors,
    applicationReferenceId,
    event,
    timestamp
  );

export const addNonCriticalEventToApplication = async ({
  applicationReferenceId,
  event,
  timestamp,
  // set desired retry parameters for API call
  maxAttempts = 4,
  delay = 2000,
  propagateErrors = false,
}) =>
  callWithRetry(
    addEventToApplication,
    maxAttempts,
    delay,
    propagateErrors,
    applicationReferenceId,
    event,
    timestamp
  );

// Verification and Validation

export const verifyCustomerEligibility = async (
  applicationReferenceId,
  payload,
  // set desired retry parameters for API call
  maxAttempts = 4,
  delay = 2000,
  propagateErrors = true
) => {
  const verifyCustomerEligibilityCall = async () => {
    const encodedAppRefId = encodeURIComponent(applicationReferenceId);
    const res = await fetch(
      `/protected/26818/small-business/sbao-app/applications/${encodedAppRefId}/verify-customer-eligibility`,
      {
        method: "POST",
        headers: getHeaders({ includeToken: true }),
        body: JSON.stringify(payload),
      }
    );

    if (!res.ok && !errorsDisabled) {
      throw new Error("Failed to verify customer eligibility");
    }

    return res.json();
  };

  return callWithRetry(verifyCustomerEligibilityCall, maxAttempts, delay, propagateErrors);
};

export const verifyEmailAddress = async ({
  applicationReferenceId,
  emailAddress,
  // set desired retry parameters for API call
  maxAttempts = 4,
  delay = 2000,
  propagateErrors = true,
  newVerificationServiceEnabled = false,
}) => {
  const verifyEmailAddressCall = async () => {
    const encodedAppRefId = encodeURIComponent(applicationReferenceId);
    const body = JSON.stringify({ emailAddress });
    const appUrlComponent = newVerificationServiceEnabled ? "dao-ui-services" : "sbao-app";
    const verifyUrlComponent = newVerificationServiceEnabled
      ? "validate-and-allowlist-email"
      : "verify-and-whitelist-email";

    const res = await fetch(
      `/protected/26818/small-business/${appUrlComponent}/applications/${encodedAppRefId}/${verifyUrlComponent}`,
      {
        method: "POST",
        headers: getHeaders({ includeToken: true, v2: newVerificationServiceEnabled }),
        body,
      }
    );

    if (!res.ok && !errorsDisabled) {
      throw new Error("Failed to verify email address");
    }

    return res.json();
  };

  return callWithRetry(verifyEmailAddressCall, maxAttempts, delay, propagateErrors);
};

export const validateStreetAddress = async (
  {
    addressLine1,
    addressLine2,
    city,
    stateCode,
    postalCode,
    // set desired retry parameters for API call
    maxAttempts = 4,
    delay = 2000,
    propagateErrors = true,
  },
  newAddressServiceEnabled = false
) => {
  const appUrlComponent = newAddressServiceEnabled ? "dao-ui-services" : "sbao-app";
  const validateUrlComponent = newAddressServiceEnabled ? "validate-address" : "verify-valid-address";
  const body = JSON.stringify({
    addressLine1,
    addressLine2,
    city,
    stateCode,
    postalCode,
  });
  const validateStreetAddressCall = async () => {
    const res = await fetch(
      `/protected/26818/small-business/${appUrlComponent}/address/${validateUrlComponent}`,
      {
        method: "POST",
        headers: getHeaders({ includeToken: true, v2: newAddressServiceEnabled }),
        body,
      }
    );

    if (!res.ok && !errorsDisabled) {
      throw new Error("Failed to verify street address");
    }

    return res.json();
  };

  return callWithRetry(validateStreetAddressCall, maxAttempts, delay, propagateErrors);
};

// Other

// Retry logic ommitted for this function, since for failed autocomplete suggestions we just fallback to a previously successful response
export const getAddressAutocompleteSuggestions = async (
  addressPrefix,
  addressProvider,
  newAddressServiceEnabled = false
) => {
  const appUrlComponent = newAddressServiceEnabled ? "dao-ui-services" : "sbao-app";
  const res = await fetch(
    `/protected/26818/small-business/${appUrlComponent}/address/autocomplete-suggestions/${addressPrefix}`,
    {
      method: "GET",
      headers: {
        ...getHeaders({ v2: newAddressServiceEnabled }),
        "Address-Provider": addressProvider,
      },
    }
  );

  if (!res.ok && !errorsDisabled) {
    throw new Error("Failed to fetch address autocomplete suggestions");
  }

  return res.json();
};

// Retry logic ommitted for this function, since it would cause a jarring user experience to wait for suggestions
export const getPlaceDetails = async (placeId, newAddressServiceEnabled = false) => {
  const appUrlComponent = newAddressServiceEnabled ? "dao-ui-services" : "sbao-app";
  const res = await fetch(
    `/protected/26818/small-business/${appUrlComponent}/address/place-details/${placeId}`,
    {
      method: "GET",
      headers: getHeaders({ v2: newAddressServiceEnabled }),
    }
  );
  return res.json();
};

// Existing customer

// Retry logic ommitted for this function, due to error handling implications with cookie rescoping
// adding retries to this method could be done as a future tech improvement
export const getPrefillInformation = async applicationReferenceId => {
  const encodedAppRefId = encodeURIComponent(applicationReferenceId);
  const res = await fetch(
    `/protected/26818/small-business/dao-ui-services/authenticated/applications/${encodedAppRefId}/pre-fill-customer`,
    {
      method: "POST",
      headers: getHeaders({ includeToken: true, v2: true }),
    }
  );

  if (!res.ok && !errorsDisabled) {
    const error = new Error(`Failed to get prefill information. Response Status: ${res?.status}`);
    const responseBody = await res.json();
    error.code = res.status;
    // NSBOAO-24966 - Once cookies are scoped - we will go through a re-direct flow based on the response from web gateway here
    if (responseBody.redirectLocation) {
      const { redirectLocation } = responseBody;
      // The following is done on purpose so that a search for /existing-customer-check will pop up here
      // We aren't able to control the redirect response but we do need to remove the trailing URL to get the redirect flow to push us back to root
      // Rather than the page this was called on
      // encodeURIComponent does not encode - so doing that manually
      // redirectLocation will be lowercased so converting stirng to lowercase as well
      const trailingUrlToRemove = encodeURIComponent("/existing-customer-check")
        .replaceAll("-", "%2d")
        .toLowerCase();
      error.redirectLocation = redirectLocation.replace(trailingUrlToRemove, "");
    }
    throw error;
  }
  // Delete the target cookie on successful pre-fill call so the applicant won't end up back on the pre-fill page
  document.cookie = "C1_TGT= ; expires = Thu, 01 Jan 1970 00:00:00 GMT;domain=.capitalone.com;";
  return res.json();
};

// Existing business

// Retry logic ommitted for this function, but could be done as a future tech improvement
export const getBusinessPrefillInformation = async (
  applicationReferenceId,
  businessSorId,
  businessSorIdType
) => {
  const body = JSON.stringify({
    businessSorId,
    businessSorIdType,
  });
  const encodedAppRefId = encodeURIComponent(applicationReferenceId);
  const res = await fetch(
    `/protected/26818/small-business/dao-ui-services/authenticated/applications/${encodedAppRefId}/pre-fill-business`,
    {
      method: "POST",
      headers: getHeaders({ includeToken: true, v2: true }),
      body,
    }
  );

  if (!res.ok && !errorsDisabled) {
    const error = new Error("Failed to get business prefill information");
    error.code = res.status;
    throw error;
  }

  return res.json();
};
