import styled from "styled-components";

import Logo from "../../../assets/logo.png";
import LogoLightMode from "../../../assets/logo-light-mode.png";
import { Col, Grid, Row, Wrapper } from "../Layout";

const Background = styled.div`
  height: var(--grv-size-spacing-xlarge-2);
  position: fixed;
  background-color: ${({ $lightMode }) =>
    $lightMode ? `var(--grv-color-white)` : `var(--grv-color-background-core-blue-60)`};
  box-shadow: ${({ $lightMode }) => ($lightMode ? `none` : `0px 4px 4px rgba(0, 0, 0, 0.25)`)};
  left: 0;
  right: 0;
  top: 0;
  z-index: var(--grv-z-index-sticky);

  @media print {
    position: relative;
    -webkit-print-color-adjust: exact !important;
    display: ${({ $hidePrintViewHeader }) => ($hidePrintViewHeader ? "none" : "block")};
  }
`;

const FullHeightWrapper = styled(Wrapper)`
  height: 100%;
`;

const FullHeightGrid = styled(Grid)`
  height: 100%;
`;

const FullHeightRow = styled(Row)`
  height: 100%;
`;

const LogoContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 100%;
`;

const LogoImage = styled.img`
  flex-shrink: 0;
  height: 39px;
  width: 112px;
  /* margin to put space between this and the dividing line */
  margin-right: 18px;

  @media print {
    position: relative;
  }
`;

const LogoText = styled.div.attrs({
  className: "grv-text grv-text--normal",
})`
  color: ${({ $lightMode }) => ($lightMode ? `var(--grv-color-core-blue-60)` : `var(--grv-color-white)`)};
  /* put a 41px tall dividing line between this and the logo */
  border-left: 1px solid
    ${({ $lightMode }) =>
      $lightMode ? `var(--grv-color-digital-gray-15)` : `var(--grv-color-digital-gray-15)`};
  padding-left: 18px;
  height: 41px;
  /* vertically center the text in the div */
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

const Header = ({ hidePrintViewHeader, lightMode }) => (
  <Background $hidePrintViewHeader={hidePrintViewHeader} $lightMode={lightMode}>
    <FullHeightWrapper>
      <FullHeightGrid>
        <FullHeightRow>
          <Col>
            <LogoContainer>
              <LogoImage alt="Capital One" src={lightMode ? LogoLightMode : Logo} />
              <LogoText $lightMode={lightMode}>Small Business</LogoText>
            </LogoContainer>
          </Col>
        </FullHeightRow>
      </FullHeightGrid>
    </FullHeightWrapper>
  </Background>
);

export default Header;
