import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import shallow from "zustand/shallow";
import {
  GENERAL_PARTNERSHIP,
  LIMITED_LIABILITY_PARTNERSHIP,
  LIMITED_PARTNERSHIP,
} from "../../../constants/partnershipTypes";
import { PLACEHOLDER_NONE } from "../../../constants/selectionValues";
import useStore from "../../../store/store";
import { CenteredRow, Col, Grid, Row, RelativePositionRow } from "../../shared/Layout";
import NavigationButtons from "../../shared/NavigationButtons";
import CommonTaxInfoFields, { commonTaxInfoFieldsValidation } from "./CommonTaxInfoFields";
import EligibilityNotice from "./EligibilityNotice";
import { PARTNERSHIP } from "../../../constants/legalEntityTypes";
import SectionHeader from "../../shared/SectionHeader";
import selectFirstFieldWithError from "../../../utils/selectFirstFieldWithError";
import useSubmitWithErrorHandling from "../../../utils/hooks/useSubmitWithErrorHandling";
import { PARTNERSHIP as TAX_CLASSIFICATION_PARTNERSHIP } from "../../../constants/businessTaxClassifications";
import useRouting from "../../../utils/hooks/useRouting";
import PartnershipStructureSelect from "../../shared/PartnershipStructureSelect";
import TaxClassificationSelect, {
  PartnershipForProfitTaxClassification,
  taxClassificationSchema,
  useDefaultTaxClassification,
} from "../../shared/TaxClassificationSelect";
import { EIN } from "../../../constants/businessTaxIdTypes";
import NonProfitRadio, { useNonProfitRadioDefaultValues } from "../../shared/NonProfitRadio";
import UnsupportedBusinessTypesSection, {
  useUnsupportedBusinessTypesDefault,
  unsupportedBusinessTypesSchema,
} from "../../shared/UnsupportedBusinessTypesSection";

const partnershipSchema = yup.object().shape({
  ...commonTaxInfoFieldsValidation,
  ...unsupportedBusinessTypesSchema,
  taxClassification: yup.string().when("isNonProfit", { is: true, then: () => taxClassificationSchema }), // submit inserts default if missing for for-profits
  partnershipStructure: yup
    .string()
    .required("Select your partnership structure")
    .notOneOf([PLACEHOLDER_NONE], "Select your partnership structure"),
});

const PartnershipTaxInfoForm = ({ areStateDocsFiled, hasMultipleBusinessOwners }) => {
  const [
    storedLegalName,
    storedTaxId,
    storedOwnershipType,
    storedBusinessTaxIdType,
    storedPartnershipStructure,
    submitPartnership,
  ] = useStore(
    state => [
      state.businessLegalName,
      state.businessTaxId,
      state.businessOwnershipType,
      state.businessTaxIdType,
      state.businessLegalEntityType &&
        [PLACEHOLDER_NONE, GENERAL_PARTNERSHIP, LIMITED_LIABILITY_PARTNERSHIP, LIMITED_PARTNERSHIP].includes(
          state.businessLegalEntityType
        ) &&
        state.businessLegalEntityType,
      state.pageSubmit.submitPartnership,
    ],
    shallow
  );
  const nonProfitRadioDefaultValues = useNonProfitRadioDefaultValues();
  const unsupportedBusinessTypesDefault = useUnsupportedBusinessTypesDefault();
  const defaultTaxClassification = useDefaultTaxClassification({
    ...nonProfitRadioDefaultValues,
    companyType: PARTNERSHIP,
  });
  const formMethods = useForm({
    resolver: yupResolver(partnershipSchema),
    shouldFocusError: false,
    defaultValues: {
      taxClassification: defaultTaxClassification,
      legalBusinessName: storedLegalName || "",
      // Check for SSN to avoid it being prefilled as an EIN
      ein: (storedBusinessTaxIdType === EIN && storedTaxId) || "",
      ownershipType: storedOwnershipType || PLACEHOLDER_NONE,
      partnershipStructure: storedPartnershipStructure || PLACEHOLDER_NONE,
      ...nonProfitRadioDefaultValues,
      ...unsupportedBusinessTypesDefault,
    },
  });
  const {
    register,
    handleSubmit,
    watch,
    formState: { isSubmitting, errors },
  } = formMethods;
  const navigate = useNavigate();
  const [backRoute, nextRoute] = useRouting();
  const isNonProfit = watch("isNonProfit");
  const submitHandler = useCallback(
    async ({
      taxClassification,
      legalBusinessName,
      ein,
      ownershipType,
      partnershipStructure,
      isUnsupportedBusinessType,
      isTrust,
    }) => {
      const { isEligibleCustomer, isEligibleBusiness } = await submitPartnership({
        isUnsupportedBusinessType,
        isTrust,
        taxClassification: isNonProfit ? taxClassification : TAX_CLASSIFICATION_PARTNERSHIP,
        legalBusinessName,
        ein,
        ownershipType,
        partnershipStructure,
        isNonProfit,
        areStateDocsFiled,
        hasMultipleBusinessOwners,
      });
      if (!isEligibleCustomer || !isEligibleBusiness) {
        navigate("/cannot-proceed");
      } else {
        navigate(nextRoute);
      }
    },
    [nextRoute, submitPartnership, navigate, isNonProfit, areStateDocsFiled, hasMultipleBusinessOwners]
  );
  const submitWithErrorHandling = useSubmitWithErrorHandling(submitHandler);

  return (
    <>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(submitWithErrorHandling, selectFirstFieldWithError)}>
          <Grid>
            <CenteredRow>
              <Col lg={8} md={8} sm={4}>
                {/* TODO: Change to sentence case when merging into HRBR feature branch */}
                <SectionHeader compact title="Business Type & Organization Status" />
              </Col>
            </CenteredRow>
            <Row>
              <Col lg={8} md={8} offset={{ lg: 2 }} sm={4}>
                <UnsupportedBusinessTypesSection />
              </Col>
            </Row>
            <RelativePositionRow>
              <Col lg={8} md={8} offset={{ lg: 2 }} sm={4}>
                <NonProfitRadio />
              </Col>
            </RelativePositionRow>
            <CenteredRow>
              <Col lg={8} md={8} sm={4}>
                <SectionHeader compact title="Tax Information" />
              </Col>
            </CenteredRow>
            <EligibilityNotice
              id="companyType_partnershipEligibilityStatement_Text"
              contentId="partnershipEligibilityStatement"
              bundleKey="partnershipEligibilityStatement"
            />
            <Row>
              <Col lg={5} md={5} offset={{ lg: 2 }} sm={4}>
                {isNonProfit ? (
                  <TaxClassificationSelect
                    {...register("taxClassification")}
                    error={errors?.taxClassification?.message}
                    companyType={PARTNERSHIP}
                    isNonProfit
                  />
                ) : (
                  <PartnershipForProfitTaxClassification />
                )}
              </Col>
            </Row>
          </Grid>
          <CommonTaxInfoFields />
          <Grid>
            <Row>
              <Col lg={5} md={5} offset={{ lg: 2 }} sm={4}>
                <PartnershipStructureSelect
                  {...register("partnershipStructure")}
                  error={errors?.partnershipStructure?.message}
                />
              </Col>
            </Row>
          </Grid>
          <NavigationButtons backRoute={backRoute} nextLoading={isSubmitting} />
        </form>
      </FormProvider>
    </>
  );
};

export default PartnershipTaxInfoForm;
