import { Controller, useFormContext } from "react-hook-form";
import NumberFormat from "react-number-format";
import * as yup from "yup";
import {
  GOVERNMENT_OWNED,
  ownershipTypesDisplay,
  PRIVATELY_OWNED,
  PUBLICLY_TRADED,
} from "../../../constants/ownershipTypes";
import { PLACEHOLDER_NONE } from "../../../constants/selectionValues";
import { blockedTINs, invalidEINPatterns } from "../../../constants/taxIdNumbers";
import { Col, Grid, Row } from "../../shared/Layout";
import SecretTextInput from "../../shared/SecretTextInput";
import Select from "../../shared/Select";
import TextInput from "../../shared/TextInput";
import { useInputId, useDropdownId } from "../../../utils/hooks/usePageScopedId";

export const commonTaxInfoFieldsValidation = {
  legalBusinessName: yup
    .string()
    .required("Enter your legal business name")
    .max(80, "Your legal business name cannot be longer than 80 letters"),
  ein: yup
    .string()
    .required("Enter your Employer Identification Number")
    .min(9, "Enter a valid 9-digit Employer Identification Number")
    .max(9, "Enter a valid 9-digit Employer Identification Number")
    .test(
      "validateEmployerIdentificationNumber",
      "Enter a valid 9-digit Employer Identification Number",
      value => !(blockedTINs.includes(value) || invalidEINPatterns.some(pat => pat.test(value)))
    ),
  ownershipType: yup
    .string()
    .required("Select a type of ownership")
    .notOneOf([PLACEHOLDER_NONE], "Select a type of ownership"),
};

const CommonTaxInfoFields = () => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext();
  const einFieldId = useInputId("EIN");

  return (
    <Grid>
      <Row>
        <Col lg={3} md={3} offset={{ lg: 2 }} sm={4}>
          <TextInput
            id={useInputId("legalBusinessName")}
            label="Legal Business Name"
            helper="This is the legal name written on your tax return or letter from the Internal Revenue Service"
            error={errors?.legalBusinessName?.message}
            {...register("legalBusinessName")}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={3} md={3} offset={{ lg: 2 }} sm={4}>
          <Controller
            render={({ field: { onChange, ref, ...rest } }) => (
              <NumberFormat
                customInput={SecretTextInput}
                id={einFieldId}
                getInputRef={ref}
                label="Employer Identification Number (EIN)"
                helper="This is the 9-digit identification number you entered on your previous year's tax return or letter from the Internal Revenue Service"
                autoComplete="off"
                placeholder="__-_______"
                format="##-#######"
                mask="_"
                secretMask="••-•••"
                hideIf={v => v.replaceAll("_", "").replaceAll("-", "").length >= 9}
                error={errors?.ein?.message}
                onValueChange={v => onChange(v.value)}
                {...rest}
              />
            )}
            name="ein"
            control={control}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={5} md={5} offset={{ lg: 2 }} sm={4}>
          <Select
            id={useDropdownId("ownershipType")}
            label="What is the majority type of ownership for your business?"
            {...register("ownershipType")}
            error={errors?.ownershipType?.message}
          >
            <option className="grv-select__placeholder" value={PLACEHOLDER_NONE} disabled>
              Select a type of ownership
            </option>
            <option value={PRIVATELY_OWNED}>{ownershipTypesDisplay[PRIVATELY_OWNED]}</option>
            <option value={PUBLICLY_TRADED}>{ownershipTypesDisplay[PUBLICLY_TRADED]}</option>
            <option value={GOVERNMENT_OWNED}>{ownershipTypesDisplay[GOVERNMENT_OWNED]}</option>
          </Select>
        </Col>
      </Row>
    </Grid>
  );
};

export default CommonTaxInfoFields;
