import { useCallback, useState } from "react";
import styled from "styled-components";
import shallow from "zustand/shallow";
import NavigationButtons from "../../shared/NavigationButtons";
import usePageTitle from "../../../utils/hooks/usePageTitle";
import { CenteredRow, Grid, Col } from "../../shared/Layout";
import Button from "../../shared/Button";
import Page from "../../shared/Page";
import Heading from "../../shared/Heading";
import useStore from "../../../store/store";
import useFocusHeading from "../../../utils/hooks/useFocusHeading";
import ProductCard from "./ProductCard";
import {
  BAS_PRODUCT_PRIMARY_CONTENT,
  BAS_PRODUCT_PRIMARY_CONTENT_HEADER,
  BAS_PRODUCT_SECONDARY_CONTENT,
  BAS_PRODUCT_SECONDARY_CONTENT_HEADER,
  BAS_PRODUCT_TAGLINE,
  BBC_PRODUCT_PRIMARY_CONTENT,
  BBC_PRODUCT_PRIMARY_CONTENT_HEADER,
  BBC_PRODUCT_SECONDARY_CONTENT,
  BBC_PRODUCT_SECONDARY_CONTENT_HEADER,
  BBC_PRODUCT_TAGLINE,
  BUC_PRODUCT_PRIMARY_CONTENT,
  BUC_PRODUCT_PRIMARY_CONTENT_HEADER,
  BUC_PRODUCT_SECONDARY_CONTENT,
  BUC_PRODUCT_SECONDARY_CONTENT_HEADER,
  BUC_PRODUCT_TAGLINE,
  BUSINESS_ADVANTAGE_SAVINGS,
  BUSINESS_BASIC_CHECKING,
  BUSINESS_UNLIMITED_CHECKING,
  convertProductTypeEnum,
  productSelectedSearchParamLookup,
} from "../../../constants/productTypes";
import getContentReader from "../../../utils/getContentReader";
import LoadingSpinnerPage from "../../shared/LoadingSpinnerPage";
import usePreventNavigation from "../../../utils/hooks/usePreventNavigation";
import { useNavigateUnlessReview } from "../../../utils/reviewContext";
import useSubmitWithErrorHandling from "../../../utils/hooks/useSubmitWithErrorHandling";
import useRouting from "../../../utils/hooks/useRouting";
import mediaQuery from "../../../utils/mediaQuery";
import { useButtonId } from "../../../utils/hooks/usePageScopedId";

const selectProductContent = (state, productType) =>
  state.content.flatMap(bundle => bundle?.products ?? []).find(bundle => bundle.productType === productType);

// Switch to single column layout at the specific screen width where the cards become too narrow to fit all of their content
const ProductSelectionGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: var(--grv-size-spacing-medium-1);

  @media (${mediaQuery.mediumLarge}) {
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: var(--grv-size-spacing-medium-1);
    grid-row-gap: var(--grv-size-spacing-small-2);
    margin-top: var(--grv-size-spacing-large-3);
  }
  ${({ $extraBottomMargin }) => ($extraBottomMargin ? "margin-bottom: 10px" : "")}
`;

const ProductSelecionSectionHeader = styled.h2`
  align-items: center;
  display: flex;
  align-self: center;
  justify-content: center;
  height: var(--grv-size-spacing-large-2);
  border: 5px 0 0 0;
  border-radius: var(--grv-size-border-radius-4);
`;

const CheckingAccountsHeader = styled(ProductSelecionSectionHeader)`
  grid-area: 1 / 1 / 2 / 2;
  margin-bottom: var(--grv-size-spacing-none);

  @media (${mediaQuery.mediumLarge}) {
    grid-area: 1 / 1 / 2 / 3;
    margin: var(--grv-size-spacing-none);
  }
`;

const SavingsAccountsHeader = styled(ProductSelecionSectionHeader)`
  grid-area: 4 / 1 / 5 / 2;
  margin: var(--grv-size-spacing-none);

  @media (${mediaQuery.mediumLarge}) {
    grid-area: 1 / 3 / 2 / 4;
  }
`;

const ProductCardsCol = styled(Col)`
  flex-direction: column;
  display: flex;
  justify-content: center;
`;

const ApplyButton = styled(Button).attrs({
  className: "grv-margin__top--medium-2",
  id: "productSelection_applyOnline_Button",
  gravityType: "progress",
})`
  min-width: 100%;
`;

const ProductSelection = () => {
  usePageTitle("Product Selection");
  usePreventNavigation();
  const headingRef = useFocusHeading();
  const [
    storedSelectedProducts,
    preSelectedProduct,
    BASContent,
    BBCContent,
    BUCContent,
    submitProductSelection,
    createApplication,
    submitGettingStarted,
    hasApplicationBeenCreated,
    isLandingPage,
  ] = useStore(
    state => [
      state.applicationProductsSelected?.map(product => product.productType) ?? [],
      productSelectedSearchParamLookup[state.queryParams?.productsSelected?.split(",", 1)[0]] ?? null,
      selectProductContent(state, BUSINESS_ADVANTAGE_SAVINGS),
      selectProductContent(state, BUSINESS_BASIC_CHECKING),
      selectProductContent(state, BUSINESS_UNLIMITED_CHECKING),
      state.pageSubmit.submitProductSelection,
      state.pageSubmit.createApplication,
      state.pageSubmit.submitGettingStarted,
      state.applicationCreated,
      state.getTargetTestValue("landingPage") === "productSelection",
    ],
    shallow
  );

  // Use the stored selected product, otherwise fall back to pre selected product
  const [selectedProducts, setSelectedProducts] = useState(
    storedSelectedProducts.length === 0 && preSelectedProduct ? [preSelectedProduct] : storedSelectedProducts
  );
  const [isNoSelectionError, setIsNoSelectionError] = useState(false);
  const [isMultipleCheckingErrorVisible, setIsMultipleCheckingErrorVisible] = useState(false);

  // Show the pre select message only if there was pre selected product or the pre selected product matches the selected product if they came back
  const showPreSelectMessage =
    preSelectedProduct &&
    (storedSelectedProducts.length === 0 || storedSelectedProducts.includes(preSelectedProduct));

  const navigate = useNavigateUnlessReview();
  const [loading, setLoading] = useState(false);
  const [backRoute, nextRoute] = useRouting();
  const submitHandler = useCallback(async () => {
    setLoading(true);
    if (selectedProducts.length === 0) {
      setIsNoSelectionError(true);
      setLoading(false);
      return;
    }
    // If multiple checking accounts are selected, display an error
    if (
      selectedProducts.length > 1 &&
      selectedProducts.includes(BUSINESS_UNLIMITED_CHECKING) &&
      selectedProducts.includes(BUSINESS_BASIC_CHECKING)
    ) {
      setIsMultipleCheckingErrorVisible(true);
      setLoading(false);
      return;
    }

    try {
      if (isLandingPage) {
        if (!hasApplicationBeenCreated) {
          await createApplication();
        }
        submitGettingStarted();
      }
      await submitProductSelection(selectedProducts);
    } finally {
      setLoading(false);
    }

    navigate(nextRoute);
  }, [
    submitProductSelection,
    selectedProducts,
    navigate,
    nextRoute,
    createApplication,
    hasApplicationBeenCreated,
    isLandingPage,
    submitGettingStarted,
  ]);
  const submitWithErrorHandling = useSubmitWithErrorHandling(submitHandler);

  const handleCardClick = useCallback(
    product => {
      // If the product that was clicked is already selected, remove it from the list; otherwise, add it to the list
      const newProductArray = selectedProducts.includes(product)
        ? selectedProducts.filter(arrayItem => arrayItem !== product)
        : [...selectedProducts, product];
      setSelectedProducts(newProductArray);

      setIsNoSelectionError(false);
      // Clear the multiple checking accounts selected error if it is resolved we need to check
      // newProductArray here since selectedProducts won't be updated until function concludes
      const isMultipleCheckingError =
        newProductArray.length > 1 &&
        newProductArray.includes(BUSINESS_UNLIMITED_CHECKING) &&
        newProductArray.includes(BUSINESS_BASIC_CHECKING);
      if (!isMultipleCheckingError) {
        setIsMultipleCheckingErrorVisible(false);
      }
    },
    [selectedProducts]
  );

  const applyOnlineButtonId = useButtonId("applyOnline");

  if (!BASContent || !BBCContent || !BUCContent) {
    return <LoadingSpinnerPage />;
  }

  const readBASContent = getContentReader(BASContent);
  const readBBCContent = getContentReader(BBCContent);
  const readBUCContent = getContentReader(BUCContent);

  return (
    <Page>
      <Grid ref={headingRef}>
        <Heading
          step={isLandingPage ? "PRODUCT SELECTION" : "PRODUCT INTEREST"}
          stepIndicatorCustomCol={10}
          mainHeadingCustomCol={10}
          subHeadingCustomCol={10}
          mainHeading={
            showPreSelectMessage
              ? "Would you like to add an additional product?"
              : "Which account(s) would you like to open?"
          }
          subHeading={
            // eslint-disable-next-line no-nested-ternary
            showPreSelectMessage ? (
              <>
                You pre-selected a{preSelectedProduct !== BUSINESS_BASIC_CHECKING ? "n" : ""}{" "}
                <span className="grv-text grv-weight--semibold">
                  {convertProductTypeEnum(preSelectedProduct)}®
                </span>{" "}
                {isLandingPage
                  ? "account. You can change your selection or add an additional product before you apply."
                  : "account. You can change your selection or add an additional product."}
              </>
            ) : isLandingPage ? (
              "We currently offer 3 types of Small Business Bank accounts. Select the account(s) you'd like to open before you apply."
            ) : (
              "We currently offer 3 types of Small Business Bank accounts. Make your selection below."
            )
          }
        />
        <CenteredRow>
          <ProductCardsCol lg={10} md={8} sm={4}>
            <ProductSelectionGrid $extraBottomMargin={isMultipleCheckingErrorVisible}>
              <CheckingAccountsHeader className="grv-color--white grv-text--medium-1 grv-weight--semibold grv-background--interaction-blue-70">
                Business Checking
              </CheckingAccountsHeader>
              <SavingsAccountsHeader className="grv-color--white grv-text--medium-1 grv-weight--semibold grv-background--core-blue-60">
                Business Savings
              </SavingsAccountsHeader>
              <ProductCard
                productType={BUSINESS_BASIC_CHECKING}
                handleCardClick={() => handleCardClick(BUSINESS_BASIC_CHECKING)}
                isSelected={selectedProducts.includes(BUSINESS_BASIC_CHECKING)}
                contentReader={readBBCContent}
                productName={convertProductTypeEnum(BUSINESS_BASIC_CHECKING)}
                productTagline={BBC_PRODUCT_TAGLINE}
                primaryContentHeader={BBC_PRODUCT_PRIMARY_CONTENT_HEADER}
                primaryContent={BBC_PRODUCT_PRIMARY_CONTENT}
                secondaryContentHeader={BBC_PRODUCT_SECONDARY_CONTENT_HEADER}
                secondaryContent={BBC_PRODUCT_SECONDARY_CONTENT}
                checkingProduct
              />
              <ProductCard
                productType={BUSINESS_UNLIMITED_CHECKING}
                handleCardClick={() => handleCardClick(BUSINESS_UNLIMITED_CHECKING)}
                isSelected={selectedProducts.includes(BUSINESS_UNLIMITED_CHECKING)}
                contentReader={readBUCContent}
                productName={convertProductTypeEnum(BUSINESS_UNLIMITED_CHECKING)}
                productTagline={BUC_PRODUCT_TAGLINE}
                primaryContentHeader={BUC_PRODUCT_PRIMARY_CONTENT_HEADER}
                primaryContent={BUC_PRODUCT_PRIMARY_CONTENT}
                secondaryContentHeader={BUC_PRODUCT_SECONDARY_CONTENT_HEADER}
                secondaryContent={BUC_PRODUCT_SECONDARY_CONTENT}
                checkingProduct
              />
              <ProductCard
                productType={BUSINESS_ADVANTAGE_SAVINGS}
                handleCardClick={() => handleCardClick(BUSINESS_ADVANTAGE_SAVINGS)}
                isSelected={selectedProducts.includes(BUSINESS_ADVANTAGE_SAVINGS)}
                contentReader={readBASContent}
                productName={convertProductTypeEnum(BUSINESS_ADVANTAGE_SAVINGS)}
                productTagline={BAS_PRODUCT_TAGLINE}
                primaryContentHeader={BAS_PRODUCT_PRIMARY_CONTENT_HEADER}
                primaryContent={BAS_PRODUCT_PRIMARY_CONTENT}
                secondaryContentHeader={BAS_PRODUCT_SECONDARY_CONTENT_HEADER}
                secondaryContent={BAS_PRODUCT_SECONDARY_CONTENT}
              />
            </ProductSelectionGrid>
            {isMultipleCheckingErrorVisible && (
              <span aria-atomic="true" role="alert" className="grv-text grv-color--interaction-red-50">
                You can only select 1 checking and 1 savings account
              </span>
            )}
          </ProductCardsCol>
        </CenteredRow>
        {isNoSelectionError && (
          <CenteredRow className="grv-margin__top--small">
            <Col lg={10} md={8} sm={4}>
              <span aria-atomic="true" role="alert" className="grv-text grv-color--interaction-red-50">
                You must select an account to continue
              </span>
            </Col>
          </CenteredRow>
        )}
        {/* AB TEST GROUP: product-selection landing page */}
        {isLandingPage && (
          <CenteredRow>
            <Col lg={4} md={4} sm={4}>
              <ApplyButton id={applyOnlineButtonId} loading={loading} onClick={submitWithErrorHandling}>
                Apply Online
              </ApplyButton>
            </Col>
          </CenteredRow>
        )}
      </Grid>
      {/* AB TEST CONTROL: getting-started landing page */}
      {!isLandingPage && (
        <NavigationButtons
          className="grv-margin__top--large"
          customCols={{ lg: 10 }}
          backRoute={backRoute}
          onNext={submitWithErrorHandling}
          preventSubmit={isNoSelectionError || isMultipleCheckingErrorVisible}
        />
      )}
    </Page>
  );
};

export default ProductSelection;
