import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import shallow from "zustand/shallow";
import LoadingSpinnerPage from "../components/shared/LoadingSpinnerPage";
import useStore from "../store/store";

export const ALLOW_FORCE_BROWSING = process.env.REACT_APP_ALLOW_FORCE_BROWSING === "true";

// this wrapper sets the reachedTerminalPage flag in the store to true.
// unless the ignoreApplication prop is provided and true,
// this wrapper navigates back to landing page if an application has not been created
export const Terminal = ({ children, ignoreApplication = false }) => {
  const [setReachedTerminalPage, landingPage] = useStore(
    state => [
      state.setReachedTerminalPage,
      state.getTargetTestValue("landingPage") === "productSelection" ? "/product-selection" : "/",
    ],
    shallow
  );
  useEffect(() => {
    setReachedTerminalPage();
  }, [setReachedTerminalPage]);

  const applicationCreated = useStore(state => state.applicationCreated);

  return applicationCreated || ignoreApplication || ALLOW_FORCE_BROWSING ? (
    children
  ) : (
    <Navigate to={landingPage} />
  );
};

// this wrapper navigates back to landing page if an application has not been created
// additionally, if the reachedTerminalPage flag is true, this wrapper navigates back to landing page and then refreshes the page
// AB test: the optional path parameter is passed in order to add appropriate conditional page protection based on AB test
export const Protected = ({ children, path = "" }) => {
  const [reachedTerminalPage, landingPage] = useStore(
    state => [
      state.reachedTerminalPage,
      state.getTargetTestValue("landingPage") === "productSelection" ? "/product-selection" : "/",
    ],
    shallow
  );

  useEffect(() => {
    if (ALLOW_FORCE_BROWSING) {
      return;
    }

    if (reachedTerminalPage) {
      window.location.reload();
    }
  }, [reachedTerminalPage]);

  const applicationCreated = useStore(state => state.applicationCreated);

  if (!ALLOW_FORCE_BROWSING && path !== landingPage) {
    if (reachedTerminalPage) {
      // display a loading spinner before refreshing
      return <LoadingSpinnerPage />;
    }

    if (!applicationCreated) {
      // just jump right back to landing page
      return <Navigate to={landingPage} />;
    }
  }

  return children;
};
