import { useEffect } from "react";
import { sendUiInteractionEvent } from "../sendAnalyticsEvent";
import useApplicationChannel from "./useApplicationChannel";

// If the appropriate identifiers are present, send a oneTag track event
const interactionEvent = (event, channel) =>
  event.target?.id &&
  event.target?.type &&
  sendUiInteractionEvent(event.type, event.target.id, event.target.type, channel);
const linkInteractionEvent = (event, channel) =>
  event.target?.href &&
  event.target?.tagName === "A" &&
  sendUiInteractionEvent(event.type, event.target.href, event.target.tagName, channel);

const useInteractionEvents = () => {
  const channel = useApplicationChannel();

  useEffect(() => {
    if (channel) {
      const interactionEventListener = event => interactionEvent(event, channel);
      const linkInteractionEventListener = event => linkInteractionEvent(event, channel);

      document.addEventListener("focus", interactionEventListener, true);
      document.addEventListener("blur", interactionEventListener, true);
      document.addEventListener("click", linkInteractionEventListener, true);

      return () => {
        document.removeEventListener("focus", interactionEventListener, true);
        document.removeEventListener("blur", interactionEventListener, true);
        document.removeEventListener("click", linkInteractionEventListener, true);
      };
    }
    return () => {};
  }, [channel]);
};

export default useInteractionEvents;
