import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import NumberFormat from "react-number-format";
import { isFuture, isExists } from "date-fns";
import * as yup from "yup";
import shallow from "zustand/shallow";
import { PLACEHOLDER_NONE } from "../../../constants/selectionValues";
import useStore from "../../../store/store";
import useFocusHeading from "../../../utils/hooks/useFocusHeading";
import usePageTitle from "../../../utils/hooks/usePageTitle";
import {
  AddressFields,
  AddressInformationTooltip,
  addressSchema,
  useAddressValidation,
} from "../../shared/Address";
import { CitizenshipFields, citizenshipSchema } from "../../shared/Citizenship";
import DisabledField from "../../shared/DisabledField";
import Heading from "../../shared/Heading";
import { CenteredRow, Row, Col, Grid } from "../../shared/Layout";
import NavigationButtons from "../../shared/NavigationButtons";
import Page from "../../shared/Page";
import SectionHeader from "../../shared/SectionHeader";
import TextInput from "../../shared/TextInput";
import { blockedTINs, ssnRejectPatterns } from "../../../constants/taxIdNumbers";
import SecretTextInput from "../../shared/SecretTextInput";
import PhoneNumberField from "../../shared/PhoneNumberField";
import usePreventNavigation from "../../../utils/hooks/usePreventNavigation";
import selectFirstFieldWithError from "../../../utils/selectFirstFieldWithError";
import useSubmitWithErrorHandling from "../../../utils/hooks/useSubmitWithErrorHandling";
import { useNavigateUnlessReview } from "../../../utils/reviewContext";
import useRouting from "../../../utils/hooks/useRouting";
import { getPhoneNumberSchema } from "../../../utils/phoneNumberValidation";
import { useInputId } from "../../../utils/hooks/usePageScopedId";

// exported for testing
export const dateOfBirthSchema = yup
  .date()
  .transform((val, original) => {
    const parts = original.split("/");
    const yearPart = parts[parts.length - 1].trim();
    const dateExists = isExists(Number(yearPart), Number(parts[0] - 1), Number(parts[1]));
    return yearPart.length === 4 && dateExists ? val : null;
  })
  .typeError("Enter a valid date of birth")
  .required("Enter a valid date of birth")
  .test(
    "validateDateOfBirth",
    "Enter a valid date of birth",
    value => !(isFuture(value) || (value && value.getFullYear() < 1900))
  );

// exported for testing
export const taxIdSchema = yup
  .string()
  .required("Enter their Social Security Number")
  .min(9, "Enter a valid 9-digit Social Security Number")
  .max(9, "Enter a valid 9-digit Social Security Number")
  .test(
    "validateSocialSecurity",
    "Enter a valid 9-digit Social Security Number",
    value => !(blockedTINs.includes(value) || ssnRejectPatterns.some(pat => pat.test(value)))
  );

const detailsSchema = yup.object().shape({
  dateOfBirth: dateOfBirthSchema,
  taxId: taxIdSchema,
  ...citizenshipSchema,
  address: addressSchema,
  phone: getPhoneNumberSchema({
    required: "Enter their primary phone number",
    invalid: "Enter a valid 10-digit primary phone number",
  }),
});

const BusinessOwnersIndividualDetails = ({ index }) => {
  usePageTitle("Individual Business Owner Details");
  usePreventNavigation();
  const headingRef = useFocusHeading();
  const [owners, submitPage] = useStore(
    state => [state.businessOwners, state.pageSubmit.submitBusinessOwnersIndividualDetails],
    shallow
  );
  const storedOwner = owners[index];

  const titleFirstName = storedOwner?.firstName?.charAt(0)?.toUpperCase() + storedOwner?.firstName?.slice(1);
  const titleName = `${titleFirstName} ${storedOwner?.lastName?.charAt(0)?.toUpperCase()}.`;

  const formMethods = useForm({
    resolver: yupResolver(detailsSchema),
    shouldFocusError: false,
    defaultValues: {
      dateOfBirth: storedOwner?.dateOfBirth ?? "",
      taxId: storedOwner?.taxId ?? "",
      citizenshipStatus: storedOwner?.citizenshipStatus ?? PLACEHOLDER_NONE,
      primaryCitizenship: storedOwner?.primaryCitizenship ?? PLACEHOLDER_NONE,
      secondaryCitizenship: storedOwner?.secondaryCitizenship ?? PLACEHOLDER_NONE,
      address: {
        addressLine1: storedOwner?.address?.addressLine1 ?? "",
        addressLine2: storedOwner?.address?.addressLine2 ?? "",
        city: storedOwner?.address?.city ?? "",
        state: storedOwner?.address?.state ?? PLACEHOLDER_NONE,
        zip: storedOwner?.address?.zip ?? "",
      },
      phone: storedOwner?.phone ?? "",
    },
  });
  const {
    control,
    handleSubmit,
    setError,
    getValues,
    formState: { errors, isSubmitting },
  } = formMethods;

  const navigate = useNavigateUnlessReview();
  const [backRoute, nextRoute] = useRouting();
  const [addressValidationState, validateAddress] = useAddressValidation({ setError, getValues });
  const onSubmit = useCallback(
    async data => {
      // show explicit block page for ITINs
      if (data.taxId.startsWith("9")) {
        navigate("/unsupported-tax-id");
        return;
      }

      const { isAddressValid, isAddressAcceptable } = await validateAddress();
      if (isAddressAcceptable) {
        await submitPage({ isAddressValid, ...data }, index);
        navigate(nextRoute);
      }
    },
    [validateAddress, submitPage, index, navigate, nextRoute]
  );
  const submitWithErrorHandling = useSubmitWithErrorHandling(onSubmit);

  const dateOfBirthFieldId = useInputId(`owner${index}DateOfBirth`);
  const taxIdFieldId = useInputId(`owner${index}TaxId`);

  return (
    <Page>
      <Grid ref={headingRef}>
        <Heading
          step="SECTION 3 OF 4"
          mainHeading={`Tell us more about ${titleName}`}
          subHeading="We need to know a little more information about each individual."
        />
        <CenteredRow>
          <Col lg={8} md={8} sm={4}>
            <SectionHeader title={`${titleName}'s personal information`} />
          </Col>
        </CenteredRow>
        <Row>
          <Col lg={4} md={4} offset={{ lg: 2 }} sm={4}>
            <DisabledField label="First Name" value={storedOwner?.firstName} />
          </Col>
        </Row>
        <Row>
          <Col lg={4} md={4} offset={{ lg: 2 }} sm={4}>
            <DisabledField label="Last Name" value={storedOwner?.lastName} />
          </Col>
        </Row>
      </Grid>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(submitWithErrorHandling, selectFirstFieldWithError)}>
          <Grid>
            <Row>
              <Col lg={2} md={2} offset={{ lg: 2 }} sm={2}>
                <Controller
                  render={({ field: { onChange, ref, ...rest } }) => (
                    <NumberFormat
                      customInput={TextInput}
                      getInputRef={ref}
                      id={dateOfBirthFieldId}
                      label="Date of Birth (MM/DD/YYYY)"
                      placeholder="__/__/____"
                      format="##/##/####"
                      mask="_"
                      error={errors?.dateOfBirth?.message}
                      onValueChange={v => onChange(v.formattedValue)}
                      {...rest}
                    />
                  )}
                  name="dateOfBirth"
                  control={control}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={2} md={2} offset={{ lg: 2 }} sm={2}>
                <Controller
                  render={({ field: { onChange, ref, ...rest } }) => (
                    <NumberFormat
                      customInput={SecretTextInput}
                      getInputRef={ref}
                      id={taxIdFieldId}
                      label="Social Security Number"
                      autoComplete="off"
                      placeholder="___-__-____"
                      format="###-##-####"
                      mask="_"
                      secretMask="•••-••-"
                      hideIf={v => v.replaceAll("_", "").replaceAll("-", "").length >= 9}
                      error={errors?.taxId?.message}
                      onValueChange={v => onChange(v.value)}
                      {...rest}
                    />
                  )}
                  name="taxId"
                  control={control}
                  shouldUnregister
                />
              </Col>
            </Row>
          </Grid>
          <Grid>
            <CenteredRow>
              <Col lg={8} md={8} sm={4}>
                <SectionHeader title={`${titleName}'s citizenship information`} compact />
              </Col>
            </CenteredRow>
          </Grid>
          <CitizenshipFields />
          <Grid>
            <CenteredRow>
              <Col lg={8} md={8} sm={4}>
                <SectionHeader title={`${titleName}'s personal contact information`} compact />
              </Col>
            </CenteredRow>
          </Grid>
          <AddressFields
            baseField="address"
            idBase={`owner${index}Address`}
            addressLine1Label={
              <span>
                Residential Street Address <AddressInformationTooltip id="owner-address-tooltip" />
              </span>
            }
            addressLine1Helper={`
              Use the address where they physically live. Submitting an unsupported address will result in
              delays in the application process and/or a decline decision.
            `}
            addressLine1DescribedBy="owner-address-tooltip"
            {...addressValidationState}
          />
          <Grid>
            <Row>
              <Col lg={2} md={2} offset={{ lg: 2 }} sm={2}>
                <PhoneNumberField control={control} label="Phone Number" idBase={`owner${index}Phone`} />
              </Col>
            </Row>
          </Grid>
          <NavigationButtons backRoute={backRoute} nextLoading={isSubmitting} />
        </form>
      </FormProvider>
    </Page>
  );
};

export default BusinessOwnersIndividualDetails;
