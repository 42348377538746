import React from "react";
import styled from "styled-components";
import { CenteredRow, Grid, Col } from "../../shared/Layout";
import Page from "../../shared/Page";
import useFocusHeading from "../../../utils/hooks/useFocusHeading";
import mediaQuery from "../../../utils/mediaQuery";

const HeaderText = styled.h1.attrs({
  className: "grv-text__heading--xlarge grv-margin__bottom--tiny grv-margin__top--normal",
})`
  text-align: center;

  @media not (${mediaQuery.medium}) {
    font-size: var(--grv-size-font-large-1);
  }
`;

const SubHeaderRow = styled(CenteredRow).attrs({
  className: "grv-margin__bottom--normal",
})`
  text-align: center;
`;

const SubheaderText = styled.span.attrs({
  className: "grv-text grv-text__heading--medium-large",
})`
  @media not (${mediaQuery.medium}) {
    font-size: var(--grv-size-font-medium-2);
  }
`;

const HelperTextRow = styled(CenteredRow)`
  margin-top: var(--grv-size-spacing-medium-2);
  text-align: center;
`;

const StyledIcon = styled.img`
  height: 128px;
  aspect-ratio: 1;
`;

const ExistingCheckTransitionalTemplate = ({ iconSrc, iconAlt, headerText, bodyText, helperText }) => {
  const headingRef = useFocusHeading();
  return (
    <Page>
      <Grid ref={headingRef}>
        <CenteredRow>
          <Col lg={8}>
            <HeaderText dangerouslySetInnerHTML={{ __html: headerText }} tabIndex="-1" />
          </Col>
        </CenteredRow>
        <SubHeaderRow>
          <Col lg={8}>
            <SubheaderText dangerouslySetInnerHTML={{ __html: bodyText }} />
          </Col>
        </SubHeaderRow>
        <CenteredRow>
          <StyledIcon alt={iconAlt} src={iconSrc} />
        </CenteredRow>
        {helperText && (
          <HelperTextRow>
            <Col lg={8}>
              <span
                className="grv-text grv-text__body--small"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: helperText }}
              />
            </Col>
          </HelperTextRow>
        )}
      </Grid>
    </Page>
  );
};

export default ExistingCheckTransitionalTemplate;
