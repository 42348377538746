import styled from "styled-components";
import shallow from "zustand/shallow";
import { Navigate } from "react-router-dom";
import { useMemo, useState } from "react";
import { ReactComponent as Checkmark } from "@c1/gravity-icons/dist/svg/ui-lined-checkmark-1-24.svg";
import DecisionTemplate from "./DecisionTemplate";
import useStore from "../../../store/store";
import { CenteredRow, Col, Grid, Wrapper } from "../../shared/Layout";
import usePageTitle from "../../../utils/hooks/usePageTitle";
import Page from "../../shared/Page";
import BlueCheckmark from "../../../assets/blue-checkmark.svg";
import BlueComputer from "../../../assets/blue-computer.svg";
import MoneyBag from "../../../assets/money-bag.svg";
import Settings from "../../../assets/settings.svg";
import mediaQuery from "../../../utils/mediaQuery";
import useFocusHeading from "../../../utils/hooks/useFocusHeading";
import getContentReader from "../../../utils/getContentReader";
import LoadingSpinnerPage from "../../shared/LoadingSpinnerPage";
import Button from "../../shared/Button";
import ConfettiBanner from "../../shared/ConfettiBanner";
import {
  APPROVED_MODE,
  NEXT_DAY_MODE,
  SUCCESS_AND_FAILURE_MODE,
  CONFLICT_MODE,
  ISSUE_OPENING_MODE,
} from "../../../constants/submitApplicationAndAccountStatuses";
import {
  BUSINESS_BASIC_CHECKING,
  BUSINESS_UNLIMITED_CHECKING,
  productKeyMapping,
  convertProductTypeEnum,
  KNOWN_PRODUCT_TYPES,
} from "../../../constants/productTypes";
import { useDecisionPlatformTreatment } from "../../../utils/hooks/usePostSubmitTreatment";
import CEMPSurvey from "../../shared/CEMPSurvey";
import { isFeatureEnabled } from "../../../utils/configSelector";
import { sendPageActionToNewRelic } from "../../../utils/newRelic";
import { useButtonId } from "../../../utils/hooks/usePageScopedId";

const DesktopHeaderGraphic = styled.div`
  display: none;
  @media (${mediaQuery.medium}) {
    display: flex;
  }
  flex-direction: column;
  align-items: center;
`;

const MobileHeaderGraphic = styled.div`
  display: flex;
  @media (${mediaQuery.medium}) {
    display: none;
  }
  flex-direction: column;
  align-items: center;

  width: 100vw;
  height: 150px;
  overflow: hidden;
  margin-bottom: var(--grv-size-spacing-medium-2);
`;

const BlueRectangleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--grv-color-background-core-blue-50);
  width: 100%;
`;

const CheckCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  aspect-ratio: 1;
  border-radius: 50%;
  z-index: var(--grv-z-index-default);

  position: absolute;
  margin-top: 0;
  border: 6px solid transparent;
  /* on mobile, fake a seamless gradient between the circle and the arc using this linear gradient with approximate, not gravity-specific colors */
  background: linear-gradient(white, white) padding-box, linear-gradient(#01689b 40%, #005782) border-box;
  @media (${mediaQuery.medium}) {
    position: relative;
    margin-top: -50px;
    /* solid border for non-mobile view */
    border: var(--grv-size-border-width-4) solid var(--grv-color-core-blue-50);
    background: var(--grv-color-background-white);
  }
`;

const CheckmarkIcon = styled(Checkmark)`
  width: 70%;
  stroke-width: 1.2;

  /* not gravity-specific color */
  stroke: #026697;
  @media (${mediaQuery.medium}) {
    stroke: var(--grv-color-core-blue-50);
  }
`;

const Arc = styled.div`
  /* shift circle upwards to create desired arc effect */
  transform: translateY(-88%);
  padding-bottom: 1000px;

  /* width of circle should exceed viewport width */
  width: 250vw;

  border-radius: 50%;
  border: 100px solid transparent;
  /* radial gradient with parameters that generally match provided design (not gravity-specific colors) */
  background: linear-gradient(white, white) padding-box,
    radial-gradient(closest-side, #028ed4 60%, #013d5b 110%) border-box;
`;

const HeaderGraphic = () => {
  const [animationLoopCount, setAnimationLoopCount] = useState(1);
  const [isAbleToLoop, setIsAbleToLoop] = useState(true);
  const increment = () => {
    // We use this loop count as a key for confetti banner, so changing the count causes it to rerender
    // and thus replay the animation
    setAnimationLoopCount(animationLoopCount + 1);
    sendPageActionToNewRelic("confetti-replay-button-clicked");
    setIsAbleToLoop(false);

    // A confetti piece animation has a max delay of 2.5 seconds and a max length of 4 seconds,
    // so maximum animation length of the confetti banner as a whole is 2.5 + 4 = 6.5 seconds.
    // Because of this, we use a timeout to prevent the animation from being replayed until
    // this duration has elapsed.
    // ignore for testing, since we are mocking ConfettiBanner anyways
    // istanbul ignore next
    setTimeout(() => {
      setIsAbleToLoop(true);
    }, 7000);
  };

  return (
    <>
      {/* displays only when at or above medium viewport min width */}
      <DesktopHeaderGraphic>
        <ConfettiBanner
          numPieces={300}
          key={animationLoopCount}
          id={`confettiBanner_${animationLoopCount}`}
        />
        <BlueRectangleWrapper>
          <CheckCircle id="confetti_Controller" onClick={() => isAbleToLoop && increment()}>
            <CheckmarkIcon alt="checkmark" />
          </CheckCircle>
        </BlueRectangleWrapper>
      </DesktopHeaderGraphic>
      {/* displays only when below medium viewport min width */}
      <MobileHeaderGraphic>
        <CheckCircle>
          <CheckmarkIcon alt="checkmark" />
        </CheckCircle>
        <Arc />
      </MobileHeaderGraphic>
    </>
  );
};

const BookingInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;

  background-color: var(--grv-color-background-white);
  color: var(--grv-color-digital-gray-100);
  @media (${mediaQuery.medium}) {
    background-color: var(--grv-color-background-core-blue-50);
    color: var(--grv-color-white);
  }
`;

const MainHeading = styled.h1.attrs({
  id: "page-main-heading",
})`
  margin: 0;
  font-weight var(--grv-font-weight-semibold);

  color: var(--grv-color-digital-gray-100);
  font-size: var(--grv-size-font-medium-3);
  @media (${mediaQuery.medium}) {
    color: var(--grv-color-white);
    font-size: var(--grv-size-font-large-2);
  }
`;

const ApplicationStatusText = styled.div`
  margin: 0;
  text-align: center;

  color: var(--grv-color-digital-gray-100);
  font-size: var(--grv-size-font-medium-1);
  font-weight var(--grv-font-weight-normal);
  @media (${mediaQuery.medium}) {
    color: var(--grv-color-white);
    font-size: var(--grv-size-font-medium-3);
    font-weight var(--grv-font-weight-light);
  }

  p {
    margin-top: var(--grv-size-spacing-medium-1);
    margin-bottom: var(--grv-size-spacing-medium-1);
  }
`;

const ProductDescriptionTable = styled.table`
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;

  margin-bottom:0;
  @media (${mediaQuery.medium}) {
    margin-bottom: var(--grv-size-spacing-medium-1)
  }

  th {
    padding-top: var(--grv-size-spacing-medium-1);
    padding-bottom: var(--grv-size-spacing-medium-1);
    font-weight var(--grv-font-weight-normal);

    border: var(--grv-size-border-width-1) solid var(--grv-color-digital-gray-15);
    padding-left: var(--grv-size-spacing-medium-1);
    padding-right: var(--grv-size-spacing-medium-1);
    font-size: var(--grv-size-font-small-1);
    color: var(--grv-color-digital-gray-100);
    @media (${mediaQuery.medium}) {
      border: var(--grv-size-border-width-2) solid var(--grv-color-white);
      padding-left: var(--grv-size-spacing-large-1);
      padding-right: var(--grv-size-spacing-large-1);
      font-size: var(--grv-size-font-medium-1);
      color: var(--grv-color-white);
    }
  }

  /* remove left border from table */
  tr th:first-child {
    border-left: 0;
  }

  /* remove right border from table */
  tr th:last-child {
    border-right: 0;
  }
`;

const TitleElementDisclosureMessage = styled.p.attrs({
  className: "grv-text--tiny",
  id: "titleDisclosureFootnote",
})`
  margin-top: var(--grv-size-spacing-medium-1);
  text-align: center;

  margin-bottom: 0;
  color: var(--grv-color-digital-gray-70);
  @media (${mediaQuery.medium}) {
    margin-bottom: var(--grv-size-spacing-medium-1);
    color: var(--grv-color-white);
  }

  /* style the link that comes from Chariot content */
  a {
    color: var(--grv-color-digital-gray-70);
    @media (${mediaQuery.medium}) {
      color: var(--grv-color-white);
    }
  }
`;

const ApplicationStatusContent = ({
  numberOfAccounts,
  treatment,
  collectiveAccountStatuses,
  productList,
  legalBusinessName,
}) => {
  // possible cases are as follows:
  //
  // single account APPROVED
  // single account PENDING
  // NOTE: single account FAILURE or ERROR will show ISSUE_OPENING_MODE instead,
  //       which does not include the TitleElement
  // NOTE: single account CONFLICT will redirect to /restart-application
  // multiple account both APPROVED
  // multiple account both PENDING
  // multiple account one APPROVED one FAILURE/ERROR
  // NOTE: multiple account combos not from the above list are not valid and will show
  //       ISSUE_OPENING_MODE, which does not include the TitleElement, or will redirect
  //       to /restart-application if any of the account statuses were CONFLICT
  if (numberOfAccounts === 1) {
    // single account APPROVED:
    if (treatment === APPROVED_MODE) {
      return (
        <p>
          You&apos;ve been <strong>approved</strong>! Your{" "}
          <strong>Business {convertProductTypeEnum(productList[0])}®</strong> account for {legalBusinessName}{" "}
          has been opened.
        </p>
      );
    }
    // single account PENDING:
    return (
      <>
        <p>
          Your <strong>Business {convertProductTypeEnum(productList[0])}®</strong> account for{" "}
          {legalBusinessName} has been <strong>approved</strong>.
        </p>
        <p>
          Our systems are working on opening your account, but we&apos;ve sent you an initial email! You can
          look for <strong>setup and account details</strong> in your personal email address within the{" "}
          <strong>next business day</strong>.
        </p>
      </>
    );
  }
  if (treatment === APPROVED_MODE) {
    // multiple account both APPROVED:
    return (
      <p>
        You&apos;ve been <strong>approved</strong>! Your{" "}
        <strong>Business {convertProductTypeEnum(productList[0])}®</strong> and{" "}
        <strong>Business {convertProductTypeEnum(productList[1])}®</strong> accounts for {legalBusinessName}{" "}
        have been opened.
      </p>
    );
  }
  if (treatment === NEXT_DAY_MODE) {
    // multiple account both PENDING:
    return (
      <>
        <p>
          Your <strong>Business {convertProductTypeEnum(productList[0])}®</strong> and{" "}
          <strong>Business {convertProductTypeEnum(productList[1])}®</strong> accounts for {legalBusinessName}{" "}
          have been <strong>approved</strong>.
        </p>
        <p>
          Our systems are working on opening your account(s), but we&apos;ve sent you an initial email! You
          can look for <strong>setup and account details</strong> in your personal email address within the{" "}
          <strong>next business day</strong>.
        </p>
      </>
    );
  }
  // multiple account one APPROVED one FAILURE/ERROR:
  return (
    <>
      <p>
        You&apos;ve been <strong>approved</strong>!
      </p>
      <p>
        Your <strong>Business {convertProductTypeEnum(collectiveAccountStatuses?.ACCOUNT_BOOKED[0])}®</strong>{" "}
        account for <strong>{legalBusinessName}</strong> has been opened.
      </p>
      <p>
        However, though your{" "}
        <strong>
          Business{" "}
          {convertProductTypeEnum(
            collectiveAccountStatuses?.FAILURE?.[0] ?? collectiveAccountStatuses?.ERROR?.[0]
          )}
          ®
        </strong>{" "}
        application has been approved, there was an error on our end and your Business{" "}
        {convertProductTypeEnum(
          collectiveAccountStatuses?.FAILURE?.[0] ?? collectiveAccountStatuses?.ERROR?.[0]
        )}
        ® account was not opened. Our team is working on it and will reach out within{" "}
        <strong>3 business days</strong>.
      </p>
    </>
  );
};

const TitleElement = ({
  readContent,
  numberOfAccounts,
  treatment,
  collectiveAccountStatuses,
  legalBusinessName,
  headingRef,
}) => {
  // map to a simple array of relevant products for easier indexing
  const productList = Object.values(collectiveAccountStatuses).reduce((acc, curr) => acc.concat(curr), []);

  // main section disclosure footnote is only shown for single product next day mode
  const includeDisclosureFootnote = treatment === NEXT_DAY_MODE && numberOfAccounts === 1;

  return (
    <BookingInfoWrapper>
      <Wrapper>
        <Grid ref={headingRef}>
          <CenteredRow>
            <Col lg={12}>
              <CenteredRow>
                <MainHeading>Congratulations!</MainHeading>
                <ApplicationStatusText>
                  <ApplicationStatusContent
                    numberOfAccounts={numberOfAccounts}
                    treatment={treatment}
                    collectiveAccountStatuses={collectiveAccountStatuses}
                    productList={productList}
                    legalBusinessName={legalBusinessName}
                  />
                </ApplicationStatusText>
                <ProductDescriptionTable>
                  <tbody>
                    <tr>
                      <th>
                        {numberOfAccounts === 1 ? (
                          // for single account, primary info is shown in left table cell
                          readContent(productKeyMapping[productList[0]].primary)
                        ) : (
                          // for multiple accounts, first product name + primary info is shown in left table cell
                          <>
                            <strong>Business {convertProductTypeEnum(productList[0])}</strong>
                            <br />
                            {readContent(productKeyMapping[productList[0]].primary)}
                          </>
                        )}
                      </th>
                      <th>
                        {numberOfAccounts === 1 ? (
                          // for single account, secondary info with footnote indicator is shown in right table cell
                          <>
                            {readContent(productKeyMapping[productList[0]].secondary)}
                            <sup>1</sup>
                          </>
                        ) : (
                          // for multiple accounts, second product name + primary info is shown in right table cell
                          <>
                            <strong>Business {convertProductTypeEnum(productList[1])}</strong>
                            <br />
                            {readContent(productKeyMapping[productList[1]].primary)}
                          </>
                        )}
                      </th>
                    </tr>
                  </tbody>
                </ProductDescriptionTable>
                {includeDisclosureFootnote && (
                  <TitleElementDisclosureMessage
                    // we need to use HTML here since one of the disclosures has a link
                    dangerouslySetInnerHTML={{
                      __html: `<sup>1</sup> ${readContent(
                        productKeyMapping[collectiveAccountStatuses?.PENDING?.[0]].disclosure
                      )}`,
                    }}
                  />
                )}
              </CenteredRow>
            </Col>
          </CenteredRow>
        </Grid>
      </Wrapper>
    </BookingInfoWrapper>
  );
};

const NextStepsWrapper = styled.div`
  width: 600px;

  margin-top: var(--grv-size-spacing-medium-2);
  @media (${mediaQuery.medium}) {
    margin-top: var(--grv-size-spacing-large-2);
  }
`;

const NextStepsHeading = styled.h3`
  padding: 0;
  margin-top: 0;
  font-weight: var(--grv-font-weight-semibold);

  font-size: var(--grv-size-font-medium-1);
  margin-bottom: var(--grv-size-spacing-medium-1);
  @media (${mediaQuery.medium}) {
    font-size: var(--grv-size-font-medium-3);
    margin-bottom: var(--grv-size-spacing-medium-2);
  }
`;

const NextStepsDescription = styled.p`
  padding: 0;
  margin-top: 0;

  margin-bottom: var(--grv-size-spacing-medium-1);
  font-size: var(--grv-size-font-small-1);
  font-weight: var(--grv-font-weight-normal);
  @media (${mediaQuery.medium}) {
    margin-bottom: var(--grv-size-spacing-medium-2);
    font-size: var(--grv-size-font-medium-2);
    font-weight: var(--grv-font-weight-light);
  }
`;

const NextStepsListItem = styled.li`
  list-style: none;
  background-image: url(${({ $iconUrl }) => $iconUrl});
  background-repeat: no-repeat;
  background-position: left top;

  padding-left: var(--grv-size-spacing-large-2);
  margin-bottom: var(--grv-size-spacing-medium-1);
  font-size: var(--grv-size-font-small-2);
  font-weight: var(--grv-font-weight-normal);
  /* custom line height needed to avoid icon clipping */
  line-height: 1.8;
  background-size: 24px;
  @media (${mediaQuery.medium}) {
    padding-left: var(--grv-size-spacing-large-3);
    margin-bottom: var(--grv-size-spacing-medium-2);
    font-size: var(--grv-size-font-medium-2);
    font-weight: var(--grv-font-weight-light);
    line-height: var(--grv-font-line-height-medium);
    background-size: 32px;
  }

  /* remove bottom margin from final list item */
  &:last-child {
    margin-bottom: 0;
  }

  /* style the additional info text within a list item */
  p {
    margin: 0;
    padding-top: var(--grv-size-spacing-small-2);
    font-weight: var(--grv-font-weight-normal);

    font-size: var(--grv-size-font-small-1);
    @media (${mediaQuery.medium}) {
      font-size: var(--grv-size-font-small-2);
    }
  }
`;

// exported for testing that the component can gracefully handle bad data
export const NextSteps = ({ treatment, collectiveAccountStatuses }) => {
  // array of valid checking products
  const checkingProducts = [BUSINESS_BASIC_CHECKING, BUSINESS_UNLIMITED_CHECKING];
  // see if a checking product was booked
  const checkingProductIsBooked =
    collectiveAccountStatuses.ACCOUNT_BOOKED?.some(product => checkingProducts.includes(product)) ?? false;

  return (
    <NextStepsWrapper id="nextSteps_list">
      <NextStepsHeading>Next Steps</NextStepsHeading>
      <NextStepsDescription>
        {/* we explicitly list the successfully booked product for SUCCESS_AND_FAILURE_MODE */}
        {treatment === SUCCESS_AND_FAILURE_MODE ? (
          <>
            <>
              Now that your Business {convertProductTypeEnum(collectiveAccountStatuses?.ACCOUNT_BOOKED?.[0])}®
              account has been opened, get it running in about 10 minutes. Accounts unfunded within 90 days
              will close.
            </>
          </>
        ) : (
          <>
            Now that your account(s) have been opened, get them running in about 10 minutes. Accounts unfunded
            within 90 days will close.
          </>
        )}
      </NextStepsDescription>
      {/* using a table here for semantic html reasons (makes this more understandable for screen readers) */}
      {/* eslint-disable-next-line */}
      <ul role="list" className="grv-padding__left--none grv-margin__top--none grv-margin__bottom--none">
        <NextStepsListItem $iconUrl={BlueCheckmark}>Apply for account(s)</NextStepsListItem>
        <NextStepsListItem $iconUrl={BlueComputer}>Set up or sign into your online account</NextStepsListItem>
        <NextStepsListItem $iconUrl={MoneyBag}>Add money to your account</NextStepsListItem>
        <NextStepsListItem $iconUrl={Settings}>
          Recommended onboarding activities
          {/* if no checking products are booked, we shouldn't tell them to order a debit card */}
          {checkingProductIsBooked ? (
            <p>
              Once you&apos;ve set up your account you can link an account, order a debit card, and invite an
              authorized signer
            </p>
          ) : (
            <p>
              Once you&apos;ve set up your account you can link an account and invite an authorized signer
            </p>
          )}
        </NextStepsListItem>
      </ul>
    </NextStepsWrapper>
  );
};

const ButtonRow = styled(CenteredRow)`
  flex-direction: column;
  display: flex;
  justify-content: center;
`;

const SetupButton = styled(Button).attrs({
  gravityType: "action",
})`
  padding-left: var(--grv-size-spacing-xlarge-1);
  padding-right: var(--grv-size-spacing-xlarge-1);

  margin-top: var(--grv-size-spacing-medium-2);
  margin-bottom: var(--grv-size-spacing-medium-2);
  @media (${mediaQuery.medium}) {
    margin-top: var(--grv-size-spacing-large-2);
    margin-bottom: var(--grv-size-spacing-large-2);
  }
`;

const DisclosureMessage = styled.p.attrs({
  className: "grv-text--tiny grv-color--digital-gray-70",
  id: "disclosureFootnote",
})`
  margin-top: 0;
  text-align: center;

  margin-bottom: 0;
  @media (${mediaQuery.medium}) {
    margin-bottom: var(--grv-size-spacing-large-2);
  }

  /* style the link that comes from Chariot content */
  a {
    color: var(--grv-color-digital-gray-70);
  }
`;

const CEMPSurveyWrapper = styled.div`
  @media (${mediaQuery.medium}) {
    /* needed because the background of this page is white */
    border-top: var(--grv-size-spacing-large-2) solid var(--grv-color-digital-gray-5);
    /* needed because the current CEMP implementation doesn't have proper spacing for medium viewport */
    padding-top: var(--grv-size-spacing-large-3);

    /* used to remove unneeded styling from the underlying CEMP implementation */
    div {
      margin-top: 0;
      box-shadow: none;
    }
  }

  /* needed because the current CEMP implementation doesn't have proper spacing for medium viewport */
  @media (${mediaQuery.large}) {
    padding-top: 0px;
  }
`;

const getDecisionInfo = accountInformation => {
  const numberOfAccounts = accountInformation.length;

  // this is just a sanity check -- containsAllValidProducts and containsNoDuplicateProducts should always be true
  const bookedProductTypes = accountInformation.map(account => account.productType);
  const containsAllValidProducts = bookedProductTypes.every(product => KNOWN_PRODUCT_TYPES.includes(product));
  const containsNoDuplicateProducts = new Set(bookedProductTypes).size === bookedProductTypes.length;

  // reformat accountInformation to a collection of arrays, where each array is keyed by accountStatus
  // and contains all productTypes with the given accountStatus
  const collectiveAccountStatuses = accountInformation.reduce((acc, curr) => {
    if (!acc[curr.accountStatus]) {
      acc[curr.accountStatus] = [];
    }
    acc[curr.accountStatus].push(curr.productType);
    return acc;
  }, {});

  let treatment;
  // we should only be evaluating 1 or 2 accounts, and all evaluated data should be valid
  if (
    numberOfAccounts === 0 ||
    numberOfAccounts > 2 ||
    !containsAllValidProducts ||
    !containsNoDuplicateProducts
  ) {
    treatment = ISSUE_OPENING_MODE;
  } else if ((collectiveAccountStatuses.ACCOUNT_BOOKED?.length ?? 0) === numberOfAccounts) {
    treatment = APPROVED_MODE;
  } else if ((collectiveAccountStatuses.PENDING?.length ?? 0) === numberOfAccounts) {
    treatment = NEXT_DAY_MODE;
  } else if ((collectiveAccountStatuses.CONFLICT?.length ?? 0) > 0) {
    treatment = CONFLICT_MODE;
  } else if (
    (collectiveAccountStatuses.ERROR?.length ?? 0) + (collectiveAccountStatuses.FAILURE?.length ?? 0) > 0 &&
    (collectiveAccountStatuses.ACCOUNT_BOOKED?.length ?? 0) > 0
  ) {
    treatment = SUCCESS_AND_FAILURE_MODE;
  } else {
    treatment = ISSUE_OPENING_MODE;
  }

  return {
    numberOfAccounts,
    treatment,
    collectiveAccountStatuses,
  };
};

const DecisionApproved = () => {
  const headingRef = useFocusHeading();

  const [accountInformation, legalBusinessName, customerExperienceSurveyEnabled] = useStore(
    state => [
      state.submissionResult?.applicationBookingDetails?.accountInformation ?? [],
      state.businessLegalName,
      isFeatureEnabled(state, "customerExperienceSurveyEnabled"),
    ],
    shallow
  );

  const { numberOfAccounts, treatment, collectiveAccountStatuses } = useMemo(
    () => getDecisionInfo(accountInformation),
    [accountInformation]
  );

  usePageTitle(treatment === ISSUE_OPENING_MODE ? "Something Went Wrong" : "You've Been Approved");

  const productInformationContent = useDecisionPlatformTreatment("approved", "approvedProductInformation");
  const issueOpeningSingleAccountContent = useDecisionPlatformTreatment(
    "approved",
    "approved_mao_one_booking_failed"
  );
  const issueOpeningMultipleAccountContent = useDecisionPlatformTreatment(
    "approved",
    "approved_mao_two_booking_failed"
  );

  const buttonId = useButtonId("approved", "setUpOnlineAccount");

  if (
    !productInformationContent ||
    !issueOpeningSingleAccountContent ||
    !issueOpeningMultipleAccountContent
  ) {
    return <LoadingSpinnerPage />;
  }
  const readContent = getContentReader(
    // this is cleaner with nested ternary
    // eslint-disable-next-line no-nested-ternary
    treatment === ISSUE_OPENING_MODE
      ? numberOfAccounts > 1
        ? issueOpeningMultipleAccountContent
        : issueOpeningSingleAccountContent
      : productInformationContent
  );

  // We shouldn't be on this page if any booking status was CONFLICT
  if (treatment === CONFLICT_MODE) {
    return <Navigate to="/restart-application" replace />;
  }

  // bottom disclosure footnote is only shown for single product approval mode
  const includeDisclosureFootnote = treatment === APPROVED_MODE && numberOfAccounts === 1;
  // onboarding steps are not shown for next day mode
  const includeNextSteps = treatment !== NEXT_DAY_MODE;

  return (
    // if the treatment is not APPROVED_MODE, NEXT_DAY_MODE, or SUCCESS_AND_FAILURE_MODE, then we show a generic account issue page
    treatment === ISSUE_OPENING_MODE ? (
      <DecisionTemplate
        iconSrc={Settings}
        headerText={readContent("headerText")}
        // utilize existing content bundles for the messaging
        bodyText={readContent(numberOfAccounts > 1 ? "bodyTextTwoFail" : "bodyText")}
      />
    ) : (
      <Page wrapChildrenInContainer={false} lightMode customPageLayout>
        <HeaderGraphic />
        <TitleElement
          readContent={readContent}
          numberOfAccounts={numberOfAccounts}
          treatment={treatment}
          collectiveAccountStatuses={collectiveAccountStatuses}
          legalBusinessName={legalBusinessName}
          headingRef={headingRef}
        />
        <Grid>
          <CenteredRow>
            <Col lg={12}>
              {includeNextSteps && (
                <>
                  <CenteredRow>
                    <NextSteps treatment={treatment} collectiveAccountStatuses={collectiveAccountStatuses} />
                  </CenteredRow>
                  <ButtonRow>
                    <SetupButton
                      id={buttonId}
                      onClick={() => window.open(readContent("setupButtonLinkTarget"), "_blank")}
                    >
                      Set up online account
                    </SetupButton>
                  </ButtonRow>
                </>
              )}
              {includeDisclosureFootnote && (
                <CenteredRow>
                  <DisclosureMessage
                    dangerouslySetInnerHTML={{
                      __html: `<sup>1</sup> ${readContent(
                        productKeyMapping[collectiveAccountStatuses?.ACCOUNT_BOOKED?.[0]].disclosure
                      )}`,
                    }}
                  />
                </CenteredRow>
              )}
            </Col>
          </CenteredRow>
        </Grid>
        {customerExperienceSurveyEnabled && (
          <CEMPSurveyWrapper>
            <CEMPSurvey />
          </CEMPSurveyWrapper>
        )}
      </Page>
    )
  );
};

export default DecisionApproved;
