import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import shallow from "zustand/shallow";
import { PLACEHOLDER_NONE } from "../../../constants/selectionValues";
import useStore from "../../../store/store";
import { CenteredRow, Col, Grid, Row, RelativePositionRow } from "../../shared/Layout";
import NavigationButtons from "../../shared/NavigationButtons";
import SectionHeader from "../../shared/SectionHeader";
import CommonTaxInfoFields, { commonTaxInfoFieldsValidation } from "./CommonTaxInfoFields";
import EligibilityNotice from "./EligibilityNotice";
import selectFirstFieldWithError from "../../../utils/selectFirstFieldWithError";
import useSubmitWithErrorHandling from "../../../utils/hooks/useSubmitWithErrorHandling";
import useRouting from "../../../utils/hooks/useRouting";
import TaxClassificationSelect, {
  taxClassificationSchema,
  useDefaultTaxClassification,
} from "../../shared/TaxClassificationSelect";
import { CORPORATION } from "../../../constants/legalEntityTypes";
import { EIN } from "../../../constants/businessTaxIdTypes";
import NonProfitRadio, { useNonProfitRadioDefaultValues } from "../../shared/NonProfitRadio";
import UnsupportedBusinessTypesSection, {
  useUnsupportedBusinessTypesDefault,
  unsupportedBusinessTypesSchema,
} from "../../shared/UnsupportedBusinessTypesSection";

const partnershipSchema = yup.object().shape({
  ...commonTaxInfoFieldsValidation,
  ...unsupportedBusinessTypesSchema,
  taxClassification: taxClassificationSchema,
});

const CorporationTaxInfoForm = ({ areStateDocsFiled, hasMultipleBusinessOwners }) => {
  const [storedLegalName, storedTaxId, storedOwnershipType, storedBusinessTaxIdType, submitCorporation] =
    useStore(
      state => [
        state.businessLegalName,
        state.businessTaxId,
        state.businessOwnershipType,
        state.businessTaxIdType,
        state.pageSubmit.submitCorporation,
      ],
      shallow
    );
  const nonProfitRadioDefaultValues = useNonProfitRadioDefaultValues();
  const unsupportedBusinessTypesDefault = useUnsupportedBusinessTypesDefault();
  const defaultTaxClassification = useDefaultTaxClassification({
    ...nonProfitRadioDefaultValues,
    companyType: CORPORATION,
  });
  const formMethods = useForm({
    resolver: yupResolver(partnershipSchema),
    shouldFocusError: false,
    defaultValues: {
      taxClassification: defaultTaxClassification,
      legalBusinessName: storedLegalName || "",
      // Check for SSN to avoid it being prefilled as an EIN
      ein: (storedBusinessTaxIdType === EIN && storedTaxId) || "",
      ownershipType: storedOwnershipType || PLACEHOLDER_NONE,
      ...nonProfitRadioDefaultValues,
      ...unsupportedBusinessTypesDefault,
    },
  });

  const {
    register,
    handleSubmit,
    watch,
    formState: { isSubmitting, errors },
  } = formMethods;
  const navigate = useNavigate();
  const [backRoute, nextRoute] = useRouting();
  const isNonProfit = watch("isNonProfit");
  const submitHandler = useCallback(
    async ({
      taxClassification,
      legalBusinessName,
      ein,
      ownershipType,
      isUnsupportedBusinessType,
      isTrust,
    }) => {
      const { isEligibleCustomer, isEligibleBusiness } = await submitCorporation({
        isUnsupportedBusinessType,
        isTrust,
        taxClassification,
        legalBusinessName,
        ein,
        ownershipType,
        isNonProfit,
        areStateDocsFiled,
        hasMultipleBusinessOwners,
      });
      if (!isEligibleCustomer || !isEligibleBusiness) {
        navigate("/cannot-proceed");
      } else {
        navigate(nextRoute);
      }
    },
    [nextRoute, submitCorporation, navigate, isNonProfit, areStateDocsFiled, hasMultipleBusinessOwners]
  );
  const submitWithErrorHandling = useSubmitWithErrorHandling(submitHandler);

  return (
    <>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(submitWithErrorHandling, selectFirstFieldWithError)}>
          <Grid>
            <CenteredRow>
              <Col lg={8} md={8} sm={4}>
                {/* TODO: Change to sentence case when merging into HRBR feature branch */}
                <SectionHeader compact title="Business Type & Organization Status" />
              </Col>
            </CenteredRow>
            <Row>
              <Col lg={8} md={8} offset={{ lg: 2 }} sm={4}>
                <UnsupportedBusinessTypesSection />
              </Col>
            </Row>
            <RelativePositionRow>
              <Col lg={8} md={8} offset={{ lg: 2 }} sm={4}>
                <NonProfitRadio />
              </Col>
            </RelativePositionRow>
            <CenteredRow>
              <Col lg={8} md={8} sm={4}>
                <SectionHeader compact title="Tax Information" />
              </Col>
            </CenteredRow>
            <EligibilityNotice
              id="companyType_corporationEligibilityStatement_Text"
              contentId="corporationEligibilityStatement"
              bundleKey="corporationEligibilityStatement"
            />
          </Grid>
          <Grid>
            <Row>
              <Col lg={5} md={5} offset={{ lg: 2 }} sm={4}>
                <TaxClassificationSelect
                  {...register("taxClassification")}
                  error={errors?.taxClassification?.message}
                  companyType={CORPORATION}
                  isNonProfit={isNonProfit} // technically does nothing but good to pass on anyway
                />
              </Col>
            </Row>
          </Grid>
          <CommonTaxInfoFields />
          <NavigationButtons backRoute={backRoute} nextLoading={isSubmitting} />
        </form>
      </FormProvider>
    </>
  );
};

export default CorporationTaxInfoForm;
