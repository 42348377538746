import { useCallback } from "react";
import { useLocation } from "react-router-dom";
import shallow from "zustand/shallow";
import { SOLE_PROPRIETORSHIP } from "../../constants/legalEntityTypes";
import useStore from "../../store/store";
import { isFeatureEnabled } from "../configSelector";
import useApplicationChannel from "./useApplicationChannel";
import { SBB_WEB } from "../../constants/applicationChannelTypes";

const getBackRoute = ({
  currentRoute,
  applicationChannel,
  isApplicantPrefilled = false,
  isNewBusiness = false,
  // used for BO pages
  isSoleProprietorship = false,
  isSingleMemberLLC = false,
  numberOfAdditionalOwners = 0,
  businessHasOtherBeneficialOwners = false,
  hasNoEligibleExistingBusinesses = false,
  businessHasMiscellaneousActivities = false,
  // feature flags
  delayedExistingCustomerCheckEnabled = false,
  landingPage = "gettingStarted",
}) => {
  switch (currentRoute) {
    case "/":
      return landingPage !== "productSelection"
        ? // AB TEST CONTROL: getting-started landing page
          // NOTE: Effectively does nothing since "/" is the current page
          "/"
        : // AB TEST GROUP: product-selection landing page
          "/product-selection";

    case "/company-type":
      if (isApplicantPrefilled && hasNoEligibleExistingBusinesses) {
        return "/applicant-information-prefilled";
      }
      if (isApplicantPrefilled && isNewBusiness) {
        return "/existing-business-selection";
      }
      return "/personal-contact-info";

    case "/applicant-information-prefilled":
      return "/applicant-information-incorrect"; // technically a Terminal page, but accessed via back button

    case "/existing-business-selection":
      return "/applicant-information-prefilled";

    case "/business-information-prefilled":
      return "/existing-business-information-incorrect"; // technically a Terminal page, but accessed via back button

    case "/additional-existing-business-information":
      return "/business-information-prefilled";

    case "/product-selection":
      return landingPage !== "productSelection"
        ? // AB TEST CONTROL: getting-started landing page
          "/"
        : // AB TEST GROUP: product-selection landing page
          // NOTE: Effectively does nothing since "/product-selection" is the current page
          "/product-selection";

    case "/customer-type-check":
      return landingPage !== "productSelection"
        ? // AB TEST CONTROL: getting-started landing page
          "/product-selection"
        : // AB TEST GROUP: product-selection landing page
          "/";

    case "/personal-info":
      // eslint-disable-next-line no-nested-ternary
      return delayedExistingCustomerCheckEnabled && applicationChannel === SBB_WEB
        ? "/customer-type-check"
        : landingPage !== "productSelection"
        ? // AB TEST CONTROL: getting-started landing page
          "/product-selection"
        : // AB TEST GROUP: product-selection landing page
          "/";

    case "/personal-contact-info":
      return "/personal-info";

    case "/business-details":
      return "/company-type";

    case "/business-contact-details":
      return isNewBusiness ? "/business-details" : "/additional-existing-business-information";

    case "/business-services/0":
      return "/business-contact-details";

    case "/business-services/1":
      return "/business-services/0";

    case "/business-services/2":
      return "/business-services/1";

    case "/business-ownership-info":
      return businessHasMiscellaneousActivities ? "/business-services/2" : "/business-services/1";

    case "/business-owners-info": // aka business owners top level
      return isNewBusiness ? "/business-ownership-info" : "/additional-existing-business-information";

    // /individual-business-owner-details falls through to default

    case "/business-controller-owners-review":
      // if there are other owners already filled out, need to go to their individual details pages
      // this is true regardless of new vs existing business
      if (numberOfAdditionalOwners > 0) {
        return `/individual-business-owner-details/${numberOfAdditionalOwners - 1}`;
      }
      // if this is an SMLLC OR indicated additional BOs (but did not add any), the only way to reach this page is from BO top level
      // this is true regardless of new vs existing business, since both ownership info and existing business info route there
      if (isSingleMemberLLC || businessHasOtherBeneficialOwners) {
        return "/business-owners-info";
      }
      // and finally, for non-SMLLCs that have no other owners, this must have come directly from
      // either the ownership info page (new businesses) or the additional existing business info page (prefilled businesses)
      return isNewBusiness ? "/business-ownership-info" : "/additional-existing-business-information";

    case "/application-review":
      // non sole props always go back to BOBC review
      // eslint-disable-next-line no-nested-ternary
      return isSoleProprietorship
        ? businessHasMiscellaneousActivities
          ? "/business-services/2"
          : "/business-services/1"
        : "/business-controller-owners-review";

    case "/terms-and-conditions":
      return "/application-review";

    default:
      if (currentRoute.startsWith("/individual-business-owner-details/")) {
        const [, , index] = currentRoute.split("/");
        const prevOwner = parseInt(index, 10) - 1;
        // if the index fails to parse, prevOwner is NaN, NaN >= 0 is false, and we fall back to top level page
        // if the index is 0, prevOwner will be -1 and we will also navigate back to the top level page
        return prevOwner >= 0 ? `/individual-business-owner-details/${prevOwner}` : "/business-owners-info";
      }
      return landingPage !== "productSelection" ? "/" : "/product-selection";
  }
};

const getNextRoute = ({
  currentRoute,
  applicationChannel,
  // used for BO pages
  isSoleProprietorship = false,
  isSingleMemberLLC = false,
  numberOfAdditionalOwners = 0,
  businessHasOtherBeneficialOwners = false,
  isApplicantPrefilled = false,
  isNewBusiness = false,
  hasNoEligibleExistingBusinesses = false,
  businessHasMiscellaneousActivities = false,
  // feature flags
  delayedExistingCustomerCheckEnabled = false,
  landingPage = "gettingStarted",
}) => {
  switch (currentRoute) {
    case "/":
      // eslint-disable-next-line no-nested-ternary
      return landingPage !== "productSelection"
        ? // AB TEST CONTROL: getting-started landing page
          "/product-selection"
        : // AB TEST GROUP: product-selection landing page
        // REMINDER: when the existing customer check is delayed till submit we
        // need to route to the existing customer self-identification page before personal info
        // for applications in the SBB_WEB channel - Branch and Cafe skip right to personal info
        delayedExistingCustomerCheckEnabled && applicationChannel === SBB_WEB
        ? "/customer-type-check"
        : "/personal-info";

    case "/applicant-information-prefilled":
      return isApplicantPrefilled && hasNoEligibleExistingBusinesses
        ? "/company-type"
        : "/existing-business-selection";

    case "/existing-business-selection":
      return isNewBusiness ? "/company-type" : "/business-information-prefilled";

    case "/business-information-prefilled":
      return "/additional-existing-business-information";

    case "/additional-existing-business-information":
      return "/business-contact-details";

    case "/company-type":
      return "/business-details";

    case "/product-selection":
      // eslint-disable-next-line no-nested-ternary
      return landingPage !== "productSelection"
        ? // AB TEST CONTROL: getting-started landing page
          // REMINDER: when the existing customer check is delayed till submit we
          // need to route to the existing customer self-identification page before personal info
          // for applications in the SBB_WEB channel - Branch and Cafe skip right to personal info
          delayedExistingCustomerCheckEnabled && applicationChannel === SBB_WEB
          ? "/customer-type-check"
          : "/personal-info"
        : // AB TEST GROUP: product-selection landing page
          "/";

    case "/customer-type-check":
      return "/personal-info";

    case "/personal-info":
      // routing for /sign-in and /enroll are done in the PersonalInfo component itself
      return "/personal-contact-info";

    case "/personal-contact-info":
      return "/company-type";

    case "/business-details":
      return "/business-contact-details";

    case "/business-contact-details":
      return "/business-services/0";

    case "/business-services/0":
      return "/business-services/1";

    case "/business-services/1":
      // eslint-disable-next-line no-nested-ternary
      return businessHasMiscellaneousActivities
        ? "/business-services/2"
        : isSoleProprietorship
        ? "/application-review"
        : "/business-ownership-info";

    case "/business-services/2":
      return isSoleProprietorship ? "/application-review" : "/business-ownership-info";

    case "/business-ownership-info":
      // the SMLLC logic is weird, but we still want to go to the (disabled) BO top level page per product and design
      return isSingleMemberLLC || businessHasOtherBeneficialOwners
        ? "/business-owners-info"
        : "/business-controller-owners-review";

    case "/business-owners-info": // aka business owners top level
      // if on this page, the user must have indicated that they have additional BOs OR are a single member LLC
      // if they are a single member LLC:
      // then we navigate to the business-controller-owners-review page as they've already entered in their details on the personal info pages.
      // otherwise, they must have indicated that they have additional BOs.
      // then they must have added at least 1 and we will always navigate to the first individual-business-owner-details page
      return isSingleMemberLLC
        ? "/business-controller-owners-review"
        : "/individual-business-owner-details/0";

    // /individual-business-owner-details falls through to default

    case "/business-controller-owners-review":
      return "/application-review";

    case "/application-review":
      return "/terms-and-conditions";

    default:
      if (currentRoute.startsWith("/individual-business-owner-details/")) {
        const [, , index] = currentRoute.split("/");
        const nextOwner = parseInt(index, 10) + 1;
        // if the index fails to parse, nextOwner is NaN, NaN < numberOfAdditionalOwners is false, and we fall back to review
        // if the index is last owner, nextOwner, we will also navigate to review
        return nextOwner < numberOfAdditionalOwners
          ? `/individual-business-owner-details/${nextOwner}`
          : "/business-controller-owners-review";
      }
      return landingPage !== "productSelection" ? "/" : "/product-selection";
  }
};

export const useBackRoute = () => {
  const { pathname: currentRoute } = useLocation();
  const applicationChannel = useApplicationChannel();

  const [
    isSoleProprietorship,
    isSingleMemberLLC,
    businessHasOtherBeneficialOwners,
    numberOfAdditionalOwners,
    isApplicantPrefilled,
    isNewBusiness,
    hasNoEligibleExistingBusinesses,
    delayedExistingCustomerCheckEnabled,
    businessHasMiscellaneousActivities,
    landingPage,
  ] = useStore(
    state => [
      state.businessLegalEntityType === SOLE_PROPRIETORSHIP,
      state.isSingleMemberLLC,
      state.hasOtherBeneficialOwners,
      state.businessOwners?.length,
      state.isApplicantPrefilled,
      state.isNewBusiness,
      state.existingCustomerEligibleBusinesses?.length === 0,
      isFeatureEnabled(state, "delayedExistingCustomerCheckEnabled"),
      state.hrbrActivities.businessHasMiscellaneousActivities,
      state.getTargetTestValue("landingPage"),
    ],
    shallow
  );

  return getBackRoute({
    currentRoute,
    applicationChannel,
    isApplicantPrefilled,
    isNewBusiness,
    isSoleProprietorship,
    isSingleMemberLLC,
    numberOfAdditionalOwners,
    businessHasOtherBeneficialOwners,
    hasNoEligibleExistingBusinesses,
    delayedExistingCustomerCheckEnabled,
    businessHasMiscellaneousActivities,
    landingPage,
  });
};

// Instead of returning the next route directly, this hook returns a function
// that can be called to retrieve the next route. That function can also be
// given overrides that allow certain pages (namely, BO pages) to make routing
// decisions based on data on that page, which would not have been sent to the
// store before this hook was called.
// Note that this is distinct from "terminal" page routing.
export const useNextRouteProvider = () => {
  const { pathname: currentRoute } = useLocation();
  const applicationChannel = useApplicationChannel();

  const [
    isSoleProprietorship,
    isSingleMemberLLC,
    businessHasOtherBeneficialOwners,
    numberOfAdditionalOwners,
    isApplicantPrefilled,
    isNewBusiness,
    hasNoEligibleExistingBusinesses,
    delayedExistingCustomerCheckEnabled,
    businessHasMiscellaneousActivities,
    landingPage,
  ] = useStore(
    state => [
      state.businessLegalEntityType === SOLE_PROPRIETORSHIP,
      state.isSingleMemberLLC,
      state.hasOtherBeneficialOwners,
      state.businessOwners?.length,
      state.isApplicantPrefilled,
      state.isNewBusiness,
      state.existingCustomerEligibleBusinesses?.length === 0,
      isFeatureEnabled(state, "delayedExistingCustomerCheckEnabled"),
      state.hrbrActivities.businessHasMiscellaneousActivities,
      state.getTargetTestValue("landingPage"),
    ],
    shallow
  );

  return useCallback(
    (overrides = {}) =>
      getNextRoute({
        currentRoute,
        applicationChannel,
        isSoleProprietorship,
        isSingleMemberLLC,
        numberOfAdditionalOwners,
        businessHasOtherBeneficialOwners,
        isApplicantPrefilled,
        isNewBusiness,
        hasNoEligibleExistingBusinesses,
        delayedExistingCustomerCheckEnabled,
        businessHasMiscellaneousActivities,
        landingPage,
        ...overrides,
      }),
    [
      currentRoute,
      applicationChannel,
      isSoleProprietorship,
      isSingleMemberLLC,
      numberOfAdditionalOwners,
      businessHasOtherBeneficialOwners,
      isApplicantPrefilled,
      isNewBusiness,
      hasNoEligibleExistingBusinesses,
      delayedExistingCustomerCheckEnabled,
      businessHasMiscellaneousActivities,
      landingPage,
    ]
  );
};

// convenience hook for when it is not necessary to override the next route provider
export const useNextRoute = () => useNextRouteProvider()();

const useRouting = () => [useBackRoute(), useNextRoute()];

export default useRouting;
