export const getCookie = (cookies, cookieName) => {
  const startIndex = cookies.indexOf(`${cookieName}=`);
  if (startIndex < 0) {
    // malformed or missing => return null for both
    return null;
  }
  const otherCookies = cookies.slice(startIndex + `${cookieName}=`.length);
  const endIndex = otherCookies.indexOf(";");
  return endIndex < 0 ? otherCookies : otherCookies.slice(0, endIndex);
};

export const getLoginTargetCookie = cookies => JSON.parse(decodeURIComponent(getCookie(cookies, "C1_TGT")));
