import { useNavigate } from "react-router-dom";
import SingleSignOnTemplate from "./SingleSignOnTemplate";
import getContentReader from "../../../utils/getContentReader";
import useStore from "../../../store/store";
import usePageTitle from "../../../utils/hooks/usePageTitle";
import LoadingSpinnerPage from "../../shared/LoadingSpinnerPage";
import useSSOParams from "../../../utils/hooks/useSSOParams";
import usePreSubmitTreatment from "../../../utils/hooks/usePreSubmitTreatment";

const SingleSignOnSignIn = () => {
  const handleApplicantPrefill = useStore(state => state.handleApplicantPrefill);
  const navigate = useNavigate();

  usePageTitle("SSO Sign In");
  const ssoParams = useSSOParams();
  const content = usePreSubmitTreatment("branchPreSubmitFriction", "ssoSignIn");

  if (!content) {
    return <LoadingSpinnerPage />;
  }

  const readContent = getContentReader(content);
  const ssoBase = readContent("buttonLinkTarget");
  const ssoUrl = `${ssoBase}${ssoParams}`;

  const attemptPrefillBeforeRedirect = async event => {
    event.preventDefault(); // stop redirect so we can check prefill endpoint first
    try {
      const nextPage = await handleApplicantPrefill();
      navigate(nextPage);
    } catch (error) {
      // only proceed to SSO page if response was 401 Unauthorized
      if (error.code === 401) {
        window.open(ssoUrl, "_self");
      } else {
        navigate("/existing-data-failure");
      }
    }
  };

  return (
    <SingleSignOnTemplate
      headerText={readContent("headerText")}
      bodyText={readContent("bodyText")}
      buttonText={readContent("buttonText")}
      clickHandler={attemptPrefillBeforeRedirect}
      buttonLinkId="ssoSignInRedirect"
    />
  );
};
export default SingleSignOnSignIn;
