import { useEffect } from "react";
import shallow from "zustand/shallow";
import useStore from "../../store/store";
import { validChannelsQuery } from "../../constants/applicationChannelTypes";
import { validProductTypesQuery } from "../../constants/productTypes";

const validLandingPages = ["", "manual-review-faq"];

// persist certain query params for downstream/marketing teams to get data in potomac
// note - these are still lost on a normal navigate call, and so will be removed after
// application create
const persistedParams = ["external_ID", "daoid"];

// for query params that we want to only have a specific set of values,
// we add a case to this switch statement to run appropriate validation
const restrictiveParams = (param, value) => {
  switch (param) {
    case "applicationChannel":
      return validChannelsQuery.includes(value);
    case "productsSelected":
      return validProductTypesQuery.includes(value);
    case "desiredLanding":
      return validLandingPages.includes(value);
    default:
      return true;
  }
};

const useQueryParams = (allowList = []) => {
  // extract all query/search params on initial load, remove them from the URL, and store them
  const [addQueryParam, setHasProcessedQueryParams] = useStore(
    state => [state.addQueryParam, state.setHasProcessedQueryParams],
    shallow
  );

  useEffect(() => {
    const url = new URL(window.location.href);
    const searchParams = Array.from(url.searchParams.entries());
    searchParams
      .filter(([param]) => allowList.includes(param))
      .filter(([param, value]) => restrictiveParams(param, value))
      .forEach(([param, value]) => addQueryParam(param, value));
    if (searchParams.length > 0) {
      searchParams
        .filter(([param]) => !persistedParams.includes(param))
        .forEach(([param]) => {
          url.searchParams.delete(param);
        });
      window.history.replaceState({}, document.title, url.href);
    }

    // needed to prevent premature redirects
    setHasProcessedQueryParams();
  }, [allowList, addQueryParam, setHasProcessedQueryParams]);
};

export default useQueryParams;
