import styled, { createGlobalStyle } from "styled-components";
import { CenteredRow, Col, Grid, Wrapper } from "../Layout";
import Header from "../Header";
import Footer from "../Footer";
import mediaQuery from "../../../utils/mediaQuery";
import { useIsReviewPage } from "../../../utils/reviewContext";

// MISSING TEST COVERAGE: There is not a straightforward way to test global styles,
// though there is a test in place that verifies the change in CSS classes when
// lightMode is toggled on
// istanbul ignore next
const Background = createGlobalStyle`
  body {
    background-color: var(--grv-color-white);
    color: var(--grv-color-digital-gray-100);
    margin: 0;
  }

  [tabindex="-1"]:focus {
    /* In the interest of accessibility, we programmatically focus the
       header of each new page of the application, so any kind of screen
       reader will immediately announce the new content. However, this causes
       an outline to appear around the header element. Ordinarily this is the
       correct behavior for focus but, these are non-interactable elements, and
       cannot be reached through the normal tab ordering (they have tabindex="-1").
       Therefore it is valid to apply an outline of 0 (NOT an outline of none)
       without causing accessibility concerns.
       To ensure there is no scenario where a normally tabbable element is caught
       by this rule, we opt for an attribute selector on the tabindex itself.
    */
    outline: 0;
  }

  @media (${mediaQuery.large}) {
    body {
      background-color: ${({ $lightMode }) =>
        $lightMode ? `var(--grv-color-white)` : `var(--grv-color-digital-gray-5)`};
    }
  }
`;

const PageContainer = styled.div.attrs({
  className: "grv-font",
})`
  display: flex;
  flex-flow: column nowrap;
  justify-content: stretch;
  align-items: center;
  min-height: 100vh;
`;

const Main = styled.main.attrs({
  role: "main",
})`
  /* Account for the header */
  margin-top: calc(var(--grv-size-spacing-large-3) + var(--grv-size-spacing-xlarge-2));
  margin-bottom: var(--grv-size-spacing-large-3);
  width: 100%;
  z-index: 1; // makes the box shadow appear over the footer

  /* for custom page non-mobile page layouts, don't apply arbitrary bottom margin */
  ${({ $customPageLayout }) =>
    $customPageLayout &&
    `
    @media (${mediaQuery.medium}) {
      margin-bottom: 0px;
    }
  `}

  @media (${mediaQuery.large}) {
    margin-bottom: 0px;
  }

  @media print {
    margin-top: 0px;
  }
`;

export const ContentContainer = styled.div`
  background-color: var(--grv-color-white);
  @media (${mediaQuery.large}) {
    // Add shadow and padding on desktop
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    padding-top: 48px;
    padding-bottom: 48px;
  }

  @media print {
    padding-top: 48px;
  }
`;

// TODO would be nice to remove the weird extra internal grid, but that
// seems to be what gives things the correct spacing

const Page = ({
  children,
  showCIPDisclaimer = false,
  wrapChildrenInContainer = true,
  hidePrintViewHeader = false,
  lightMode = false,
  customPageLayout = false,
}) =>
  useIsReviewPage() ? (
    children
  ) : (
    <PageContainer>
      <Background $lightMode={lightMode} />
      <Header hidePrintViewHeader={hidePrintViewHeader} lightMode={lightMode} />
      <Main $customPageLayout={customPageLayout}>
        {customPageLayout ? (
          children
        ) : (
          <Wrapper>
            <Grid>
              <CenteredRow>
                <Col lg={12}>
                  {wrapChildrenInContainer ? <ContentContainer>{children}</ContentContainer> : children}
                </Col>
              </CenteredRow>
            </Grid>
          </Wrapper>
        )}
      </Main>
      <Footer showCIPDisclaimer={showCIPDisclaimer} />
    </PageContainer>
  );

export default Page;
