export const sendPageViewEvent = channel => {
  window.oneTag?.track("view", {
    event_name: "page_view",
    customer_type: channel,
  });
};

export const sendUiInteractionEvent = (interaction, target, type, channel) => {
  window.oneTag?.track("link", {
    event_name: "ui_interaction",
    customer_type: channel,
    ui_interaction_action: interaction,
    ui_interaction_element_name: target,
    ui_interaction_element_type: type,
  });
};
