import shallow from "zustand/shallow";
import styled from "styled-components";
import { useCallback, useState } from "react";
import { ReactComponent as Megaphone } from "@c1/gravity-icons/dist/svg/ui-filled-megaphone-1-24.svg";
import { ReactComponent as PlusIcon } from "@c1/gravity-icons/dist/svg/ui-lined-plus-1-24.svg";
import useFocusHeading from "../../../utils/hooks/useFocusHeading";
import usePageTitle from "../../../utils/hooks/usePageTitle";
import usePreventNavigation from "../../../utils/hooks/usePreventNavigation";
import Heading from "../../shared/Heading";
import { Grid, Col, CenteredRow } from "../../shared/Layout";
import Banner from "../../shared/Banner";
import NavigationButtons from "../../shared/NavigationButtons";
import Page from "../../shared/Page";
import { useNavigateUnlessReview } from "../../../utils/reviewContext";
import useSubmitWithErrorHandling from "../../../utils/hooks/useSubmitWithErrorHandling";
import useStore from "../../../store/store";
import BusinessCard from "./BusinessCard";
import {
  SOLE_PROPRIETORSHIP,
  LIMITED_PARTNERSHIP,
  GENERAL_PARTNERSHIP,
  LIMITED_LIABILITY_PARTNERSHIP,
  C_CORPORATION,
  S_CORPORATION,
  NON_PROFIT,
  LIMITED_LIABILITY_COMPANY,
} from "../../../constants/legalEntityTypes";
import { ReactComponent as CharityIcon } from "../../../assets/charity.svg";
import { ReactComponent as UserIcon } from "../../../assets/user.svg";
import { ReactComponent as MultiUserIcon } from "../../../assets/multi_user.svg";
import { ReactComponent as BusinessIcon } from "../../../assets/business.svg";
import { ReactComponent as OfficeIcon } from "../../../assets/office.svg";
import LoadingSpinnerPage from "../../shared/LoadingSpinnerPage";
import mediaQuery from "../../../utils/mediaQuery";
import { useBackRoute, useNextRouteProvider } from "../../../utils/hooks/useRouting";
import { INVALID_KYC_FIELDS_EMAIL_ADDRESS } from "../../../constants/prefillValues";

const BusinessCardsCol = styled(Col)`
  display: flex;
  flex-wrap: wrap;
  gap: var(--grv-size-spacing-medium-1);

  & > * {
    flex: 0 0 100%;

    @media (${mediaQuery.medium}) {
      flex-basis: calc(50% - 8px);
    }

    @media (${mediaQuery.xlarge}) {
      flex-basis: calc(33.33% - 10.66px);
    }
  }
`;

const NavButtonsSpacer = styled.div`
  min-height: var(--grv-size-spacing-large-3);
`;

const getLegalEntityIcon = legalEntityType => {
  switch (legalEntityType) {
    case SOLE_PROPRIETORSHIP:
      return <UserIcon />;
    case LIMITED_PARTNERSHIP:
    case GENERAL_PARTNERSHIP:
    case LIMITED_LIABILITY_PARTNERSHIP:
      return <MultiUserIcon />;
    case C_CORPORATION:
    case S_CORPORATION:
      return <OfficeIcon />;
    case NON_PROFIT:
      return <CharityIcon />;
    case LIMITED_LIABILITY_COMPANY:
    default:
      return <BusinessIcon />;
  }
};

const ExistingBusinessSelection = () => {
  usePageTitle("Existing Business Selection");
  usePreventNavigation();
  const headingRef = useFocusHeading();

  const [
    storedIsNewBusiness,
    storedIsNonProfit,
    storedExistingCustomerEligibleBusinesses,
    storedSelectedExistingBusiness,
    submitExistingBusinessSelection,
  ] = useStore(
    state => [
      state.isNewBusiness,
      state.isNonProfit,
      state.existingCustomerEligibleBusinesses,
      state.selectedExistingBusiness,
      state.pageSubmit.submitExistingBusinessSelection,
    ],
    shallow
  );

  const [selectedExistingBusiness, setSelectedExistingBusiness] = useState(storedSelectedExistingBusiness);
  const [isNewBusiness, setIsNewBusiness] = useState(storedIsNewBusiness);
  const [isNonProfit, setIsNonProfit] = useState(storedIsNonProfit);
  const [isError, setIsError] = useState(false);

  const navigate = useNavigateUnlessReview();
  const backRoute = useBackRoute();
  const nextRouteProvider = useNextRouteProvider();

  const handleClick = useCallback(
    existingBusiness => {
      setIsError(false);
      if (existingBusiness.businessSorId) {
        setIsNewBusiness(false);
        if (existingBusiness.businessSorId !== selectedExistingBusiness.businessSorId) {
          setSelectedExistingBusiness(existingBusiness);
          setIsNonProfit(
            (existingBusiness.legalEntityType === NON_PROFIT || existingBusiness.isNonProfit) ?? false
          );
        }
      } else {
        setSelectedExistingBusiness({});
        setIsNonProfit(false);
        setIsNewBusiness(true);
      }
    },
    [selectedExistingBusiness]
  );

  const onSubmit = useCallback(async () => {
    if (Object.keys(selectedExistingBusiness).length === 0 && !isNewBusiness) {
      setIsError(true);
      return;
    }
    const { prefillFailure, prefillFailureReason } = await submitExistingBusinessSelection({
      selectedExistingBusiness,
      isNewBusiness,
      isNonProfit,
    });
    if (prefillFailure) {
      // We route to invalid-email-address if email address is the only failure reason
      if (prefillFailureReason === INVALID_KYC_FIELDS_EMAIL_ADDRESS) {
        navigate("/invalid-email-address");
      } else {
        navigate("/cannot-proceed");
      }
    } else {
      navigate(nextRouteProvider({ isNewBusiness }));
    }
  }, [
    isNewBusiness,
    isNonProfit,
    selectedExistingBusiness,
    submitExistingBusinessSelection,
    navigate,
    nextRouteProvider,
  ]);
  const submitWithErrorHandling = useSubmitWithErrorHandling(onSubmit);

  if (storedExistingCustomerEligibleBusinesses === null) {
    return <LoadingSpinnerPage />;
  }

  return (
    <Page>
      <Grid ref={headingRef}>
        <Heading
          step="ABOUT YOUR COMPANY"
          mainHeading="Which business are you opening an account for?"
          subHeading="Select one of the businesses listed below."
        />
        <CenteredRow className="grv-margin__top--large-3">
          <Col lg={8} md={8} sm={4}>
            <Banner
              bannerText="These are the businesses currently associated with your account"
              bannerIcon={<Megaphone alt="" role="presentation" />}
            />
          </Col>
        </CenteredRow>
      </Grid>
      <Grid>
        {isError && (
          <CenteredRow className="grv-margin__top--large-3">
            <Col xl={8} lg={8} md={8} sm={4}>
              <span className="grv-text grv-color--interaction-red-50">
                You must make a selection to continue
              </span>
            </Col>
          </CenteredRow>
        )}
        <CenteredRow className={`${isError ? "grv-margin__top--small-2" : "grv-margin__top--large-1"}`}>
          <BusinessCardsCol className="grv-margin__top--medium-1" xl={8} lg={8} md={8} sm={4}>
            {storedExistingCustomerEligibleBusinesses.map(existingBusiness => (
              <BusinessCard
                key={existingBusiness.businessSorId}
                content={existingBusiness}
                handleClick={handleClick}
                isSelected={existingBusiness.businessSorId === selectedExistingBusiness?.businessSorId}
                icon={
                  existingBusiness.isNonProfit
                    ? getLegalEntityIcon(NON_PROFIT)
                    : getLegalEntityIcon(existingBusiness.legalEntityType)
                }
                isNonProfit={existingBusiness.isNonProfit}
              />
            ))}
            <BusinessCard
              content={{
                legalBusinessName: "I want to open an account for business not listed here",
              }}
              handleClick={handleClick}
              isSelected={isNewBusiness}
              icon={<PlusIcon />}
            />
          </BusinessCardsCol>
        </CenteredRow>
      </Grid>
      <NavButtonsSpacer />
      <NavigationButtons backRoute={backRoute} onNext={submitWithErrorHandling} />
    </Page>
  );
};

export default ExistingBusinessSelection;
