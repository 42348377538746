import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import shallow from "zustand/shallow";
import getContentReader from "../../../utils/getContentReader";
import useStore from "../../../store/store";
import usePageTitle from "../../../utils/hooks/usePageTitle";
import LoadingSpinnerPage from "../../shared/LoadingSpinnerPage";
import examineIcon from "../../../assets/ExamineIconAnimated.svg";
import ExistingCheckTransitionalTemplate from "./ExistingCheckTransitionalTemplate";
import { sendErrorToNewRelic } from "../../../utils/newRelic";

const selectExistingCustomerCheckContent = state =>
  state.content
    .flatMap(bundle => bundle?.existingCustomerFlow?.existingCustomerFlowContent ?? [])
    .find(bundle => bundle.contentId === "existingCustomerCheck");

const ExistingCustomerCheck = () => {
  const [existingCustomerCheckContent, handleApplicantPrefill] = useStore(
    state => [selectExistingCustomerCheckContent(state), state.handleApplicantPrefill],
    shallow
  );
  usePageTitle("Existing Customer Check");
  const navigate = useNavigate();
  const content = existingCustomerCheckContent;

  useEffect(() => {
    const fetchPrefill = async () => {
      const nextPage = await handleApplicantPrefill();
      navigate(nextPage);
    };

    // catch error here because of issue with try/catch in useEffect
    // reference: https://github.com/testing-library/react-hooks-testing-library/issues/305
    fetchPrefill().catch(error => {
      console.log(error);
      sendErrorToNewRelic(error);
      // NSBOAO-24966 - Cookie rescoping will throw an error and redirect that we will need to handle
      if (error?.code === 401 && error?.redirectLocation) {
        window.open(error.redirectLocation, "_self");
      } else {
        navigate("/existing-data-failure");
      }
    });
  }, [navigate, handleApplicantPrefill]);

  if (!content) {
    return <LoadingSpinnerPage />;
  }

  const readContent = getContentReader(content);

  return (
    <ExistingCheckTransitionalTemplate
      iconSrc={examineIcon}
      iconAlt=""
      headerText={readContent("headerText")}
      bodyText={readContent("bodyText")}
      helperText="If you have an existing Small Business Bank account with us, we may be able to fill in part of your application. If not, no worries! You can still get started with the application."
    />
  );
};
export default ExistingCustomerCheck;
