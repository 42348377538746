import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { sendPageViewEvent } from "../sendAnalyticsEvent";
import useApplicationChannel from "./useApplicationChannel";
import { sendPageActionToNewRelic } from "../newRelic";

const usePageEvents = () => {
  const location = useLocation();
  const channel = useApplicationChannel();

  // Send page_view event to OneTag & NR every time a new, valid page is visited
  useEffect(() => {
    if (channel) {
      sendPageViewEvent(channel);
      sendPageActionToNewRelic("pageView", { location: location?.pathname });
    }
  }, [location, channel]);
};

export default usePageEvents;
